import k from "./keys";

export default {
  [k._]: `:`,
  [k._1]: `(`,
  [k._2]: `)`,
  [k.LIGHT]: `สว่าง`,
  [k.ANGEL]: `นางฟ้า`,
  [k.DARK]: `มืด`,
  [k.DEMON]: `ปีศาจ`,
  [k.SELECT_ALL]: `เลือกทั้งหมด`,
  [k.DESELECT_ALL]: `ยกเลิกการเลือกทั้งหมด`,
  [k.ALSBVYETHVN]: `alsbvyethvn`,
  [k.ALASKA]: `alaska`,
  [k.BSKJFH_KSJLHFVKLFSJB]: `bskjfh ksjlhfvklfsjb`,
  [k.CPQWHG_SKUGBHOWSFVJH]: `cpqwhg skugbhowsfvjh`,
  [k.DWQEFHKDVB_LASHDVSDALN_SJBVKSF]: `dwqefhkdvb lashdvsdaln sjbvksfjbv`,
  [k.SDV_ASODJHVSF_SLDHGVKSUFGV]: `sdv ;asodjhvsf sldhgvksufgv`,
  [k.KJSBFV]: `kjsbfv`,
  [k.POWQJIF]: `powqjif`,
  [k.XCUYWE]: `xcuywe`,
  [k.POIQRF]: `poiqrf`,
  [k.LABEL]: `ป้าย`,
  [k.NONE]: `ไม่มี`,
  [k.SYSTEM]: `ระบบ`,
  [k._3]: `0`,
  [k.CHECKBOX]: `ช่องทำเครื่องหมาย`,
  [k.LOADING_CHART]: `กำลังโหลดแผนภูมิ`,
  [k.ADD_A_CHART]: `+ เพิ่มแผนภูมิ`,
  [k.CREATED_BY]: `สร้างโดย:`,
  [k.DELETE_DASHBOARD]: `ลบแดชบอร์ด`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_DELET]: `คุณแน่ใจหรือไม่ว่าต้องการลบ`,
  [k.PINNED_CHARTS_WILL_NOT_BE_DELE]: `แผนภูมิที่ปักหมุดจะไม่ถูกลบ.`,
  [k.CANCEL]: `ยกเลิก`,
  [k.DELETE]: `ลบ`,
  [k.DASHBOARD]: `แดชบอร์ด`,
  [k.CREATE_NEW]: `+ สร้างใหม่`,
  [k.REFRESH_DASHBOARD]: `รีเฟรชแดชบอร์ด`,
  [k.DELETE_DASHBOARD1]: `ลบแดชบอร์ด`,
  [k.BUTTON]: `ปุ่ม`,
  [k.NO_DASHBOARD_SELECTED]: `ยังไม่ได้เลือกแดชบอร์ด`,
  [k.SELECT]: `เลือก`,
  [k.ADDBUTTON]: `เพิ่มปุ่ม`,
  [k.U_B_ADD]: `\u002B เพิ่ม`,
  [k._4]: ``,
  [k._5]: `-`,
  [k.YOU_ARE_NOT_AUTHORIZED_CLICK]: `คุณไม่ได้รับอนุญาต คลิกที่นี่เพื่อดูรายละเอียด.`,
  [k.ADD]: `เพิ่ม`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_CANCE]: `คุณแน่ใจหรือไม่ว่าต้องการยกเลิกการอนุมัติ? การกระทำนี้จะปลดล็อกฟิลด์ในเช็คลิสต์ เปลี่ยนสถานะ และยกเลิกการอนุมัติก่อนหน้านี้.`,
  [k.PLEASE_ENTER_A_COMMENT]: `โปรดใส่ความคิดเห็น`,
  [k.NO]: `ไม่`,
  [k.YES]: `ใช่`,
  [k.CANCELLATION]: `การยกเลิก -`,
  [k.HIDE]: `ซ่อน`,
  [k.SHOW]: `แสดง`,
  [k.LINE_THROUGH]: `ขีดเส้นทับ`,
  [k.INCOMPLETE]: `ไม่สมบูรณ์`,
  [k.CONFIGURE_AD_HOC_SIGNATURE]: `กำหนดลายเซ็นเฉพาะกิจ`,
  [k.NUMBER_OF_SIGNATORIES]: `จำนวนผู้ลงนาม`,
  [k.USERS]: `ผู้ใช้`,
  [k.GROUPS]: `กลุ่ม`,
  [k.CONFIRM]: `ยืนยัน`,
  [k.REQUEST_APPROVAL]: `ขออนุมัติ`,
  [k.ADD_SIGNATURE]: `+ เพิ่มลายเซ็น`,
  [k.APPLY_YOUR_SIGNATURE]: `ใช้ลายเซ็นของคุณ`,
  [k.TYPE]: `ประเภท`,
  [k.APPROVAL]: `การอนุมัติ`,
  [k.REJECTION]: `การปฏิเสธ`,
  [k.EMAIL_ID]: `อีเมล`,
  [k.OTP_SENT_AGAIN_PLEASE_CHECK_Y]: `ส่งรหัส OTP ใหม่แล้ว โปรดตรวจสอบอีเมลของคุณ.`,
  [k.OTP_SENT_TO_THE_MENTIONED_EMAI]: `ส่งรหัส OTP ไปยังที่อยู่อีเมลที่ระบุแล้ว.`,
  [k.RESEND]: `ส่งใหม่`,
  [k.PASSWORD]: `รหัสผ่าน`,
  [k.OTP]: `OTP`,
  [k.INCORRECT_OTP]: `OTP ไม่ถูกต้อง`,
  [k.GENERATE_OTP]: `สร้าง OTP`,
  [k.APPROVAL1]: `อนุมัติ`,
  [k.COMMENT]: `ความคิดเห็น`,
  [k.UPLOAD_YOUR_OWN_SIGNATURE]: `อัปโหลดลายเซ็นของคุณเอง`,
  [k.ATTACH_FILE]: `แนบไฟล์`,
  [k.USE_SYSTEM_GENERATED_SIGNATURE]: `ใช้ลายเซ็นที่ระบบสร้าง`,
  [k.CONFIRM_AND_SIGN]: `ยืนยันและลงนาม`,
  [k.RED]: `สีแดง`,
  [k.ENTER_EMAIL]: `ใส่อีเมล`,
  [k.UPLOAD_NEW]: `อัปโหลดใหม่`,
  [k.UPLOAD]: `อัปโหลด`,
  [k.BY]: `โดย:`,
  [k.YOU]: `คุณ`,
  [k.ID]: `ID:`,
  [k.MEMBERS]: `สมาชิก`,
  [k.MEMBER]: `สมาชิก`,
  [k.USER]: `ผู้ใช้-`,
  [k.GROUP]: `กลุ่ม-`,
  [k.REMOVE_SELECTED_USER]: `ลบผู้ใช้ที่เลือก`,
  [k.LIST]: `รายการ`,
  [k.USER1]: `ผู้ใช้`,
  [k.GROUP1]: `กลุ่ม`,
  [k.LINK]: `ลิงก์`,
  [k.ENTER_USER_S_EMAIL]: `ใส่อีเมลของผู้ใช้`,
  [k.ADD_NEW_USER]: `เพิ่มผู้ใช้ใหม่`,
  [k.SELECT_USERS]: `เลือกผู้ใช้`,
  [k.ENTER_VALID_EMAIL]: `กรุณาใส่อีเมลที่ถูกต้อง`,
  [k.TOGGLEUSERDROPDOWN]: `สลับเมนูผู้ใช้`,
  [k.REMOVEUSER]: `ลบผู้ใช้`,
  [k.DECREMENT]: `ลดลง`,
  [k.NO_USER_SELECTED]: `ยังไม่ได้เลือกผู้ใช้`,
  [k.ADD1]: `เพิ่ม`,
  [k.ENTER_TITLE]: `ใส่ชื่อเรื่อง`,
  [k.SEARCH]: `ค้นหา`,
  [k.PRIVATE]: `ส่วนตัว`,
  [k.LOADING]: `กำลังโหลด...`,
  [k.CREATE]: `+ สร้าง`,
  [k.REMOVE]: `ลบ`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_REMOV]: `คุณแน่ใจหรือไม่ว่าต้องการลบ`,
  [k.FROM_THE_CHECKLIST]: `ออกจากเช็คลิสต์?`,
  [k.CONVERSATION]: `การสนทนา`,
  [k.VIEW_ALL_FIELDS]: `ดูฟิลด์ทั้งหมด`,
  [k.UPDATE]: `อัปเดต`,
  [k._7]: `#`,
  [k.NO_TITLE]: `ไม่มีชื่อ`,
  [k.MAKE_AS_CURRENT_REVISION]: `กำหนดให้เป็นรีวิชันปัจจุบัน`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_MAKE]: `คุณแน่ใจหรือไม่ว่าต้องการกำหนด`,
  [k.AS_THE_CURRENT_REVISION]: `เป็นรีวิชันปัจจุบัน?`,
  [k.CURRENT_REVISION]: `รีวิชันปัจจุบัน`,
  [k.CURRENTREVISION]: `currentRevision`,
  [k.CURRENT]: `ปัจจุบัน`,
  [k.MAKE_CURRENT_REVISION]: `กำหนดให้เป็นรีวิชันปัจจุบัน`,
  [k.MAKE_CURRENT]: `ทำให้เป็นปัจจุบัน`,
  [k.VIEWING_NOW]: `กำลังดู`,
  [k.NO_TITLE1]: `ไม่มีชื่อ`,
  [k.DONE]: `เสร็จสิ้น`,
  [k.MORE]: `...เพิ่มเติม`,
  [k.UNIFIZE_UNIFIZEBLUE]: `unifize_unifizeBlue`,
  [k.CREATE_NEW1]: `สร้างใหม่`,
  [k.DOCUMENT]: `เอกสาร`,
  [k.ENTER_TITLE1]: `ใส่ชื่อเรื่อง`,
  [k.NOTE_YOUR_DOCUMENT_WILL_BE_SH]: `หมายเหตุ: เอกสารของคุณจะถูกแชร์กับผู้เข้าร่วมการสนทนาทั้งหมด`,
  [k.CREATE1]: `สร้าง`,
  [k.FILEICONS]: `fileIcons`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_DELET1]: `คุณแน่ใจหรือไม่ว่าต้องการลบ "`,
  [k.INLINE_BLOCK]: `inline-block`,
  [k._9]: `.`,
  [k._10]: `"`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_REMOV1]: `คุณแน่ใจหรือไม่ว่าต้องการลบไฟล์นี้?`,
  [k.REMOVE_FROM_CHECKLIST]: `นำออกจากเช็คลิสต์`,
  [k.FROM_UNIFIZE_FILE_STORE]: `จากพื้นที่เก็บไฟล์ของ Unifize`,
  [k.UPLOADING_FILE]: `กำลังอัปโหลดไฟล์...`,
  [k.OTHERS]: `อื่น ๆ`,
  [k.PREVIEW]: `ตัวอย่าง`,
  [k.EDIT]: `แก้ไข3`,
  [k.FILECONTAINER]: `fileContainer`,
  [k.FILENAME]: `fileName`,
  [k._11]: `|`,
  [k.UPLOADED_ON]: `อัปโหลดเมื่อ:`,
  [k.NO_PREVIEW_AVAILABLE]: `ไม่มีตัวอย่างให้ดู`,
  [k.CHECKLIST]: `เช็คลิสต์`,
  [k.PICK_SOURCE]: `เลือกแหล่งที่มา`,
  [k.UNIFIZE_FILE_STORE]: `พื้นที่เก็บไฟล์ของ Unifize`,
  [k.COMPUTER]: `คอมพิวเตอร์`,
  [k.NO_SOURCE_SELECTED]: `ยังไม่ได้เลือกแหล่งที่มา`,
  [k._12]: `,`,
  [k.SELECT_FORM]: `+ เลือกฟอร์ม`,
  [k.AUTO_SELECT_ALL_REVISIONS]: `เลือกรีวิชันทั้งหมดโดยอัตโนมัติ`,
  [k.SELECT_MANUALLY]: `เลือกด้วยตนเอง`,
  [k.BACK]: `ย้อนกลับ`,
  [k.AUTO_SELECT_ALL_REVISIONS_IS]: `การเลือกรีวิชันทั้งหมดอัตโนมัติคือ`,
  [k.EDIT_SELECTED_REVISION_S]: `แก้ไขรีวิชันที่เลือก`,
  [k.ALL_REVISIONS]: `รีวิชันทั้งหมด`,
  [k.SELECT_REVISIONS]: `เลือกรีวิชัน`,
  [k.REMOVE_LINK_OR_REASSIGN_REVISI]: `ลบลิงก์หรือกำหนดรีวิชันใหม่?`,
  [k.THIS_CONVERSATION_HAS_MULTIPLE]: `การสนทนานี้มีหลายรีวิชัน คุณต้องการลบลิงก์ทั้งหมดหรือไม่?`,
  [k.REASSIGN_REVISIONS]: `กำหนดรีวิชันใหม่`,
  [k.REMOVE_LINK]: `ลบลิงก์`,
  [k.ADDCONVERSATIONBUTTON]: `ปุ่มเพิ่มการสนทนา`,
  [k.AUTO_SELECT_CURRENT_REVISION]: `เลือกรีวิชันปัจจุบันอัตโนมัติ`,
  [k.AUTO_SELECT_CURRENT_REVISION_I]: `การเลือกรีวิชันปัจจุบันอัตโนมัติคือ`,
  [k.EDIT_SELECTED_REVISION]: `แก้ไขรีวิชันที่เลือก`,
  [k.SELECT_REVISION]: `เลือกรีวิชัน`,
  [k.NEW_ITEM]: `รายการใหม่`,
  [k.ITEM]: `รายการ "`,
  [k.SELECT_CHECKLIST_FIELDS]: `เลือกฟิลด์เช็คลิสต์`,
  [k.CREATE_VERSION]: `สร้างเวอร์ชัน`,
  [k.CREATE_NEW_REVISION]: `สร้างรีวิชันใหม่`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_CREAT]: `คุณแน่ใจหรือไม่ว่าต้องการสร้างรีวิชัน?`,
  [k.CANCELLERS]: `ผู้ยกเลิก`,
  [k.ALLOWED_CANCELLERS]: `ผู้มีสิทธิ์ยกเลิก`,
  [k.ALLOWED_SIGNATORIES]: `ผู้มีสิทธิ์ลงนาม`,
  [k.ROLES]: `บทบาท`,
  [k.FIELDS]: `ฟิลด์`,
  [k.SPECIFIC_USERS]: `ผู้ใช้เฉพาะ`,
  [k.SELECT_THE_DATA_TO_COPY_TO_NEW]: `เลือกข้อมูลที่จะคัดลอกไปยังรีวิชันใหม่`,
  [k.CHECKLIST_AND_FORM_DATA]: `ข้อมูลเช็คลิสต์และฟอร์ม`,
  [k.SELECT_FIELDS]: `เลือกฟิลด์`,
  [k.REVISION_NOTES]: `หมายเหตุรีวิชัน`,
  [k.WHY_ARE_YOU_MAKING_THIS_REVISI]: `ทำไมคุณถึงสร้างรีวิชันนี้?`,
  [k.CREATE_REVISION]: `สร้างรีวิชัน`,
  [k.REVISION]: `รีวิชัน`,
  [k.CREATE_REVISION1]: `สร้างรีวิชัน`,
  [k.CONVERSATION1]: `การสนทนา`,
  [k.NO_MATCHING_RESULTS]: `ไม่พบผลลัพธ์ที่ตรงกัน.`,
  [k.ADD_NEW_USER1]: `+ เพิ่มผู้ใช้ใหม่`,
  [k.CHECKLISTREMOVE]: `checklistRemove`,
  [k.UPLOAD_NEW_VERSION]: `อัปโหลดเวอร์ชันใหม่`,
  [k.DOWNLOAD]: `ดาวน์โหลด`,
  [k.MMM_DD_YYYY]: `ดดด DD, ปี YYYY`,
  [k.NOT_ASSIGNED]: `ยังไม่ได้กำหนด`,
  [k.SEND_AN_EMAIL_TO_CONVERSATION]: `ส่งอีเมลไปยังการสนทนา`,
  [k.FILES]: `ไฟล์`,
  [k.DATA]: `ข้อมูล`,
  [k.INFO]: `ข้อมูล`,
  [k.CHECKLIST1]: `เช็คลิสต์`,
  [k.JSON_FOR_ADOBE]: `JSON สำหรับ Adobe`,
  [k.JSON_FOR_PDFGENERATORAPI]: `JSON สำหรับ PDFGeneratorAPI`,
  [k.SAVE]: `บันทึก`,
  [k.REFRESH]: `รีเฟรช`,
  [k.TO_SEE_LATEST_CHECKLIST_DATA]: `เพื่อดูข้อมูลเช็คลิสต์ล่าสุด`,
  [k.CREATOR]: `ผู้สร้าง`,
  [k.DATE_CREATED]: `วันที่สร้าง`,
  [k.MMM_DD_YYYY_HH_MM_A]: `ดดด DD, YYYY hh:mm a`,
  [k.AGE]: `อายุ`,
  [k.DAYS]: `วัน`,
  [k.DAYS1]: `วัน`,
  [k.PARENT1]: `หัวข้อหลัก:`,
  [k.CHILD]: `, หัวข้อย่อย:`,
  [k.LEAVE_CONVERSATION]: `ออกจากการสนทนา`,
  [k.PUBLIC]: `สาธารณะ`,
  [k.HIDE_CHAT_CONTENT]: `ซ่อนเนื้อหาการสนทนา`,
  [k.HIDE_EVERYTHING]: `ซ่อนทั้งหมด`,
  [k.ADD_NEW_FIELDS]: `เพิ่มฟิลด์ใหม่`,
  [k.PRIVACY_SETTINGS]: `การตั้งค่าความเป็นส่วนตัว`,
  [k.NORMAL]: `ปกติ`,
  [k.MAKE_PRIVATE]: `ตั้งเป็นส่วนตัว`,
  [k.ONLY_PARTICIPANTS_CAN_ACCESS_T]: `เฉพาะผู้เข้าร่วมเท่านั้นที่สามารถเข้าถึงการสนทนาเหล่านี้`,
  [k.HIDE_ONLY_CHAT_CONTENT]: `ซ่อนเฉพาะเนื้อหาการสนทนา`,
  [k.ONLY_THE_CONTENTS_OF_THE_CONVE]: `จะซ่อนเฉพาะเนื้อหาของการสนทนา ชื่อเรื่อง สถานะ และเจ้าของจะยังคงมองเห็นได้`,
  [k.CONVERSATIONS_WILL_BE_VISIBLE]: `การสนทนาจะปรากฏเฉพาะกับผู้เข้าร่วม แอดมิน และผู้ใช้ในรายการที่อนุญาตเท่านั้น.`,
  [k.REMINDERS]: `การแจ้งเตือน`,
  [k.REMINDERS1]: `การแจ้งเตือน`,
  [k.WHATSAPP]: `WhatsApp`,
  [k.ON]: `เปิด`,
  [k.OFF]: `ปิด`,
  [k.LEFTHANDLE]: `leftHandle`,
  [k.DOWNLOAD_PROHIBITED]: `ผู้ดูแลระบบห้ามดาวน์โหลดไฟล์`,
  [k.DOWNLOADING_FILES_IS_PROHIBITE]: `ผู้ดูแลระบบห้ามดาวน์โหลดไฟล์`,
  [k.WE_RE_SORRY_SOMETHING_S_GONE]: `ขออภัย มีบางอย่างผิดพลาด`,
  [k.OUR_TEAM_HAS_BEEN_NOTIFIED_BU]: `ทีมของเราได้รับแจ้งแล้ว แต่`,
  [k.LET_US_KNOW]: `แจ้งให้เราทราบ`,
  [k.WHAT_HAPPENED]: `ว่าเกิดอะไรขึ้น.`,
  [k.BROWSER]: `เบราว์เซอร์:`,
  [k.VERSION]: `เวอร์ชัน:`,
  [k.UID]: `UID:`,
  [k.URL]: `URL:`,
  [k.ORG]: `องค์กร:`,
  [k.TRY_AGAIN]: `ลองใหม่`,
  [k.CHROME]: `Chrome`,
  [k.FIREFOX]: `Firefox`,
  [k.MICROSOFT_INTERNET_EXPLORER]: `Microsoft Internet Explorer`,
  [k.SAFARI]: `Safari`,
  [k.EDGE]: `Edge`,
  [k.OPERA]: `Opera`,
  [k.COULD_NOT_FETCH_CHAT_ROOMS]: `ไม่สามารถดึงข้อมูลห้องสนทนา:`,
  [k.RETRY]: `ลองอีกครั้ง`,
  [k._13]: `+`,
  [k.SCROLLCOLUMN_D]: `scrollColumn2d`,
  [k.HOME]: `หน้าแรก`,
  [k.WELCOME_BACK]: `ยินดีต้อนรับกลับ!`,
  [k.LOADING_USER]: `กำลังโหลดผู้ใช้...`,
  [k.YOUR_HOME_SCREEN_HAS_NOT_BEEN]: `หน้าหลักของคุณยังไม่ได้ถูกตั้งค่าโดยผู้ดูแลองค์กร`,
  [k.UNIFIZE_HOME_NOT_SET_UP]: `ยังไม่ได้ตั้งค่าโฮม Unifize`,
  [k.CONTACT_YOUR_ORG_ADMIN_TO_SET]: `โปรดติดต่อผู้ดูแลองค์กรเพื่อตั้งค่าโฮม Unifize ของคุณ`,
  [k.HERE_S_WHAT_S_GOING_ON_AT]: `นี่คือสิ่งที่กำลังเกิดขึ้นที่`,
  [k.CLOSE1]: `ปิด`,
  [k.SKIP_INVITE]: `ข้ามการเชิญ`,
  [k.DO_NOT_ADD_CREATOR_AS_OWNER]: `ไม่เพิ่มผู้สร้างเป็นเจ้าของ`,
  [k.DO_NOT_ADD_CREATOR_AS_PARTICIP]: `ไม่เพิ่มผู้สร้างเป็นผู้เข้าร่วม`,
  [k.DO_NOT_SEND_CONFIRMATION_TO_SE]: `ไม่ส่งการยืนยันไปยังผู้ส่ง`,
  [k.DISABLE_AUTO_NUMBER]: `ปิดใช้งานหมายเลขอัตโนมัติ`,
  [k.CONVERT_TO_FEED]: `แปลงเป็นฟีด`,
  [k.SLA_IN_DAYS_AUTO_FILL_DUE_DAT]: `SLA เป็นจำนวนวัน (กรอกกำหนดเวลาทันทีเมื่อสร้างการสนทนา)`,
  [k.EMAILS_SENT_HERE_WILL_START_A]: `อีเมลที่ส่งมาที่นี่จะเริ่มอินสแตนซ์ใหม่`,
  [k.DO_NOT_SHOW_THIS_PROCESS_IN]: `ไม่แสดงกระบวนการนี้ใน + สร้างใหม่`,
  [k.DO_NOT_INVITE_SENDER_TO_CONVER]: `ไม่เชิญผู้ส่งเข้าสู่การสนทนา`,
  [k.NEXT_COUNT]: `จำนวนถัดไป`,
  [k.NOTIFY_GROUPS]: `แจ้งเตือนกลุ่ม`,
  [k.ENABLE_PAGINATION_IN_PROCESS_T]: `เปิดใช้การแบ่งหน้าตารางกระบวนการ`,
  [k.ENABLE_PAGINATION_IN_REPORTS]: `เปิดใช้การแบ่งหน้าในรายงาน`,
  [k.ALLOW_ADHOC_ARCHIVING]: `อนุญาตให้จัดเก็บแบบเฉพาะกิจ`,
  [k.ADD_FIELD]: `เพิ่มฟิลด์`,
  [k.FIELD1]: `ฟิลด์`,
  [k.DOWNLOAD_SAMPLE_CSV]: `ดาวน์โหลด CSV ตัวอย่าง`,
  [k.ADD_FIELD1]: `เพิ่มฟิลด์`,
  [k.BEHAVIOR]: `พฤติกรรม`,
  [k.MANDATORY_IF_NOT_HIDDEN]: `จำเป็นหากไม่ได้ถูกซ่อน`,
  [k.VALUE]: `ค่า`,
  [k.CONDITION]: `เงื่อนไข`,
  [k.FORM_DATA]: `ข้อมูลแบบฟอร์ม`,
  [k.CHECKLIST_DATA]: `ข้อมูลรายการตรวจสอบ`,
  [k.CONVERSATION_DATA]: `ข้อมูลการสนทนา`,
  [k.SHOW_UNTIL_CONDITION_MET]: `แสดงจนกว่าเงื่อนไขจะเป็นจริง`,
  [k.HIDDEN_UNTIL_CONDITION_MET]: `ซ่อนจนกว่าเงื่อนไขจะเป็นจริง`,
  [k.MANDATORY_UNTIL_CONDITION_MET]: `จำเป็นจนกว่าเงื่อนไขจะเป็นจริง`,
  [k.DISABLE_UNTIL_CONDITION_MET]: `ปิดใช้งานจนกว่าเงื่อนไขจะเป็นจริง`,
  [k.COPY]: `คัดลอก`,
  [k.ADD_CONDITION_AND]: `+ เพิ่มเงื่อนไข (AND)`,
  [k.ADD_NEW_CONDITION]: `+ เพิ่มเงื่อนไขใหม่`,
  [k.DELETE_FIELD]: `ลบฟิลด์`,
  [k._17]: `?`,
  [k.FIELDNAMEINPUT]: `fieldNameInput-`,
  [k.FIELDSETTINGS]: `การตั้งค่าฟิลด์`,
  [k.FORM]: `ฟอร์ม`,
  [k.NOT_ALLOWED]: `ไม่อนุญาต`,
  [k.OLDSETTINGS]: `oldSettings`,
  [k.PROMPT_WHOM]: `แจ้งเตือนใคร`,
  [k.WHEN]: `เมื่อ`,
  [k.CREATE_RULES_TO_PROMPT_USERS_T]: `สร้างกฎเพื่อแจ้งเตือนผู้ใช้ให้ทำขั้นตอนนี้ ผู้ใช้ที่ระบุจะเห็นฟิลด์เหล่านี้ภายในบทสนทนา`,
  [k.CREATE_NEW_RULE]: `สร้างกฎใหม่`,
  [k.AUTO_ARCHIVE]: `จัดเก็บอัตโนมัติ`,
  [k.SEND_MESSAGE]: `ส่งข้อความ`,
  [k.CHANGE_STATUS_TO]: `เปลี่ยนสถานะเป็น`,
  [k.ADD_PARTICIPANTS]: `เพิ่มผู้เข้าร่วม`,
  [k.REMOVE_PARTICIPANTS]: `ลบผู้เข้าร่วม`,
  [k.UPDATE_PRIVACY]: `อัปเดตความเป็นส่วนตัว`,
  [k.WHITELIST]: `Whitelist`,
  [k.DON_T_CHANGE]: `ไม่เปลี่ยนแปลง`,
  [k.MAKE_PUBLIC]: `ตั้งเป็นสาธารณะ`,
  [k.UNARCHIVE]: `ยกเลิกการเก็บ`,
  [k.IS_NOT_SUPPORTED]: `ไม่รองรับ`,
  [k.GET_SIGNATURE_OF_ANY]: `รับลายเซ็นของใครก็ได้`,
  [k.USER_S]: `ผู้ใช้`,
  [k.SELECT_AD_HOC]: `เลือกแบบเฉพาะกิจ`,
  [k.WHO_CAN_CANCEL]: `ใครสามารถยกเลิกได้`,
  [k.WHEN_CAN_THEY_CANCEL]: `เมื่อไหร่ที่พวกเขาสามารถยกเลิก`,
  [k.HIDE_WHEN_INACTIVE]: `ซ่อนเมื่อไม่ใช้งาน`,
  [k.DISABLE_WHEN_INACTIVE]: `ปิดใช้งานเมื่อไม่ใช้งาน`,
  [k.CONTINUE_TO_LOCK_FIELDS_UNDER]: `ยังคงล็อกฟิลด์ภายใต้การอนุมัติก่อนหน้า`,
  [k.LOCK_CHECKLIST_FIELDS_DURING_A]: `ล็อกฟิลด์เช็คลิสต์ระหว่างและหลังการอนุมัติ/ปฏิเสธ:`,
  [k.LOCK_STATUS_PREVENT_USERS_FRO]: `ล็อกสถานะ (ป้องกันไม่ให้ผู้ใช้เปลี่ยนสถานะ)`,
  [k.CHANGE_OWNER_TO]: `เปลี่ยนเจ้าของเป็น`,
  [k.SEND_A_MESSAGE]: `ส่งข้อความ`,
  [k.MARK_REVISION_AS_CURRENT]: `ทำเครื่องหมายรีวิชันเป็นปัจจุบัน`,
  [k.CANCEL_ALL_PREVIOUS_APPROVALS]: `ยกเลิกการอนุมัติทั้งหมดก่อนหน้า (ภายในลำดับ)`,
  [k.SELECT_CHECKLIST_FIELD]: `เลือกฟิลด์เช็คลิสต์`,
  [k.DELETED_FIELD]: `ฟิลด์ที่ถูกลบ`,
  [k.PROCESS]: `กระบวนการ`,
  [k.EMBEDDED_FIELDS]: `ฟิลด์ฝังตัว`,
  [k.ADD_EMBEDED_FIELD]: `เพิ่มฟิลด์แบบฝัง`,
  [k.ONLY_ALLOW_CREATING_NEW_CONVER]: `อนุญาตให้สร้างการสนทนาใหม่เท่านั้น`,
  [k.ONLY_ALLOW_SELECTING_EXISTING]: `อนุญาตให้เลือกการสนทนาที่มีอยู่เท่านั้น`,
  [k.ALLOW_BOTH_SELECTING_AND_CREAT]: `อนุญาตให้ทั้งเลือกและสร้างได้`,
  [k.ALLOW_MULTIPLE]: `อนุญาตหลายรายการ`,
  [k.ALLOW_ARCHIVED_CONVERSATIONS]: `อนุญาตให้ใช้การสนทนาที่เก็บไว้`,
  [k.SHOW_FIELD_NAMES_IN_PREVIEW]: `แสดงชื่อฟิลด์ในตัวอย่าง`,
  [k.SHOW_STATUS_DUE_DATE]: `แสดงสถานะ วันกำหนด`,
  [k.AUTO_FILL_RELATED_CONVERSATION]: `กรอกการสนทนาที่เกี่ยวข้องอัตโนมัติ`,
  [k.SUPRESS_TITLE_HYPERLINK_TO_THE]: `ระงับไฮเปอร์ลิงก์ชื่อเรื่องไปยังระเบียน`,
  [k.SORTBY]: `เรียงตาม:`,
  [k.CREATED_DATE]: `วันที่สร้าง`,
  [k.ADDED_DATE]: `วันที่เพิ่ม`,
  [k.SELECT_EXISTING_BY]: `เลือกที่มีอยู่ด้วย`,
  [k.TITLE]: `ชื่อเรื่อง`,
  [k.PLACEHOLDER]: `ตัวยึดตำแหน่ง`,
  [k.ALLOW_OFFICE_INTEGRATION]: `อนุญาตให้รวมเข้ากับ Office 365`,
  [k.YOU_MUST_SELECT_A_DEFAULT_FOLD]: `คุณต้องเลือกโฟลเดอร์เริ่มต้นใน SharePoint/Office 365 เพื่ออัปโหลดหรือสร้างไฟล์`,
  [k.AUTHENTICATE_AND_SELECT_FOLDER]: `ยืนยันตัวตนและเลือกโฟลเดอร์`,
  [k.VIEW]: `ดู`,
  [k.FOLDER]: `โฟลเดอร์`,
  [k.CONSUMPTION_OPTIONS]: `ตัวเลือกการใช้งาน:`,
  [k.ALLOW_SINGLE]: `อนุญาตให้มีได้เพียงไฟล์เดียว`,
  [k.PREVIEW_OPTIONS]: `ตัวเลือกการแสดงตัวอย่าง:`,
  [k.SHOW_FULL_PREVIEW]: `แสดงตัวอย่างเต็ม`,
  [k.SORT_BY]: `เรียงตาม:`,
  [k.DATE]: `วันที่`,
  [k.NAME]: `ชื่อ`,
  [k.DEFAULT_FILE]: `ไฟล์เริ่มต้น:`,
  [k.SUPPRESS_NOTIFICATIONS_ON_DEFA]: `ระงับการแจ้งเตือนในค่าเริ่มต้น`,
  [k.FILE_UPLOAD_OPTIONS]: `ตัวเลือกการอัปโหลดไฟล์:`,
  [k.UPLOAD_FROM_COMPUTER]: `อัปโหลดจากคอมพิวเตอร์`,
  [k.OFFICE_ONEDRIVE_SHAREPOI]: `Office 365 OneDrive / Sharepoint`,
  [k.FOLDER_PATH]: `เส้นทางโฟลเดอร์`,
  [k.THE_CREATED_FILE_CAN_BE_EDITED]: `ไฟล์ที่สร้างสามารถแก้ไขโดยสมาชิกห้องสนทนาได้`,
  [k.ADD_A_FORM]: `+ เพิ่มฟอร์ม`,
  [k.AUTO_FILL_RELATED_CONVERSATION1]: `กรอกการสนทนาที่เกี่ยวข้องอัตโนมัติ`,
  [k.SHOW_COMPRESSED_PREVIEW]: `แสดงตัวอย่างแบบย่อ`,
  [k.SHOW_STATUS_OWNER_AND_DUE_DAT]: `แสดงสถานะ เจ้าของ และวันกำหนด`,
  [k.SUPPRESS_TITLE_HYPERLINK_TO_TH]: `ระงับไฮเปอร์ลิงก์ชื่อเรื่องไปยังระเบียน`,
  [k.LINKED_FIELD_RELATION]: `ความสัมพันธ์ฟิลด์ที่เชื่อมโยง:`,
  [k.CHILD1]: `child`,
  [k.PARENT2]: `Parent`,
  [k.CHILD2]: `Child`,
  [k.OTHER_OPTIONS]: `ตัวเลือกอื่น:`,
  [k.ALLOW_ONLY_SELECTING]: `อนุญาตให้เลือกเท่านั้น`,
  [k.ALLOW_SELECTING_AND_CREATING]: `อนุญาตให้เลือกและสร้าง`,
  [k.ALLOW_ONLY_CREATING]: `อนุญาตให้สร้างเท่านั้น`,
  [k.ONLY_SHOW_LINKED_FIELDS]: `แสดงเฉพาะฟิลด์ที่เชื่อมโยง`,
  [k.REVISIONS]: `รีวิชัน:`,
  [k.ALLOW_MANUAL_REVISION_LINKING]: `อนุญาตให้เชื่อมโยงรีวิชันด้วยตนเองแม้ว่า`,
  [k.THERE_ARE_NO_REVISIONS]: `ไม่มีรีวิชัน`,
  [k.CREATED]: `created`,
  [k.MAX]: `ค่าสูงสุด`,
  [k.MIN]: `ค่าต่ำสุด`,
  [k.MAX_VALUE_SHOULD_BE_MORE_THAN]: `ค่าสูงสุดต้องมากกว่าค่าต่ำสุด`,
  [k.STEP]: `Step`,
  [k.MINIMUM_PLACES_TO_DISPLAY_AFTE]: `จำนวนตำแหน่งทศนิยมขั้นต่ำที่จะแสดง (แสดงศูนย์ต่อท้ายหากผู้ใช้ใส่น้อยกว่านี้)`,
  [k.IF_THE_USER_ENTERS_MORE_DIGITS]: `หากผู้ใช้ใส่ตัวเลขหลังทศนิยมมากกว่าจำนวนที่กำหนด:`,
  [k.DON_T_ROUND_OFF_OR_TRUNCATE]: `ไม่ปัดเศษหรือตัดทอน`,
  [k.ROUND_OFF_WHEN_DISPLAYING_BUT]: `ปัดเศษตอนแสดงผล แต่เก็บค่าจริงทั้งหมด`,
  [k.DEFAULT_VALUE]: `ค่าเริ่มต้น`,
  [k.SUPPRESS_NOTIFICATIONS_FOR_DEF]: `ระงับการแจ้งเตือนสำหรับค่าเริ่มต้น`,
  [k.TYPE1]: `ประเภท`,
  [k.UNIFIZEPDF]: `unifizePdf`,
  [k.UNIFIZE_US]: `unifize.us`,
  [k.DELETED_FIELD1]: `(ฟิลด์ที่ถูกลบ)!`,
  [k.CHECKLIST_FIELD_WHERE_NATIVE_F]: `ฟิลด์เช็คลิสต์ที่อยู่ตำแหน่งไฟล์เดิม`,
  [k.UPLOAD_TEMPLATE]: `UPLOAD TEMPLATE`,
  [k.DOWNLOAD1]: `ดาวน์โหลด`,
  [k.FETCHING]: `กำลังดึงข้อมูล`,
  [k.UPLOAD_TEMPLATE1]: `อัปโหลดเทมเพลต`,
  [k.CLICK_ON_STITCH_TO_CREATE_A_P]: `(คลิกที่ สาน เพื่อสร้าง PDF)`,
  [k.STITCH]: `+ สาน`,
  [k.BUTTON_TEXT_OPTIONAL]: `ข้อความบนปุ่ม (ไม่บังคับ)`,
  [k.NAME_OF_SAVED_REPORTS_OPTIONA]: `ชื่อของรายงานที่บันทึก (ไม่บังคับ)`,
  [k.PREVIEW_OPTIONS1]: `ตัวเลือกการแสดงตัวอย่าง`,
  [k.UPLOAD_TO_ANOTHER_FILE_FIELD]: `อัปโหลดไปยังฟิลด์ไฟล์อื่น:`,
  [k.DON_T_ATTACH_FILE_TO_CHECKLIST]: `ไม่แนบไฟล์ลงเช็คลิสต์`,
  [k.ATTACH_LATEST_VERSION_ONLY]: `แนบเฉพาะเวอร์ชันล่าสุด`,
  [k.ATTACH_ALL_VERSIONS]: `แนบทุกเวอร์ชัน`,
  [k.SORT_BY1]: `เรียงตาม`,
  [k.DATE_ADDED]: `วันที่เพิ่ม`,
  [k.SEARCH_USERS]: `ค้นหาผู้ใช้`,
  [k.OPEN_BY_DEFAULT_FOR]: `เปิดโดยค่าเริ่มต้นสำหรับ`,
  [k.OPTIONS]: `ตัวเลือก`,
  [k.ADD_OPTIONS_DYNAMICALLY]: `เพิ่มตัวเลือกแบบไดนามิก`,
  [k.DEFAULT1]: `ค่าเริ่มต้น`,
  [k.ALPHABETICAL]: `เรียงตามตัวอักษร`,
  [k.PENDINGVALUE]: `pendingValue`,
  [k.COMPLETEDVALUE]: `completedValue`,
  [k.SELECT_A_STATUS]: `เลือกสถานะ`,
  [k.TRIANGLEDOWN]: `triangleDown`,
  [k.MULTILINE]: `หลายบรรทัด`,
  [k.USER2]: `User`,
  [k.MULTIPLE]: `หลายรายการ`,
  [k.SELECT_A_FORM_TO_ADD_CONDITION]: `เลือกฟอร์มเพื่อเพิ่มเงื่อนไข`,
  [k.UNSTYLED]: `ไม่มีสไตล์`,
  [k.D_AUTO_LAYOUT]: `การจัดเรียงอัตโนมัติ 2 มิติ`,
  [k.MAXIMUM_FIELD_WIDTH_SMALLER_W]: `ความกว้างฟิลด์สูงสุด (ค่าต่ำจะทำให้มีฟิลด์มากขึ้นในหนึ่งแถว)`,
  [k.FORCE_START_ON_A_NEW_ROW]: `เริ่มต้นแถวใหม่เสมอ`,
  [k.X]: `1x`,
  [k.X1]: `2x`,
  [k.X2]: `3x`,
  [k.MAX_WIDTH]: `ความกว้างสูงสุด`,
  [k.CONDITIONS]: `เงื่อนไข`,
  [k.PROMPTS]: `การแจ้งเตือน`,
  [k.LAYOUT]: `เลย์เอาต์`,
  [k.FORM_CONDITIONS]: `เงื่อนไขฟอร์ม`,
  [k.CANCEL_CHANGES]: `ยกเลิกการเปลี่ยนแปลง`,
  [k.UPDATE_FIELD_SETTINGS]: `อัปเดตการตั้งค่าฟิลด์`,
  [k.RESOLVE_AND_UPDATE]: `แก้ไขและอัปเดต`,
  [k.ME1]: `ฉัน`,
  [k.ADDFIELDS]: `addFields`,
  [k.HOLLOWPLUSCIRCLE]: `hollowPlusCircle`,
  [k.ADD_FIELDS]: `เพิ่มฟิลด์`,
  [k.THE_CHECKLIST_HAS_NOT_BEEN_CON]: `เช็คลิสต์ยังไม่ได้รับการกำหนดค่า`,
  [k.USE_THE_ADD_FIELD_BUTTON_TO_ST]: `ใช้ปุ่ม "เพิ่มฟิลด์" เพื่อเริ่มสร้าง หรืออัปโหลด Excel`,
  [k.CHECKLIST_LAYOUT_DEFAULTS]: `ค่าเริ่มต้นเลย์เอาต์ของเช็คลิสต์`,
  [k.CHECKLIST_OPEN_BY_DEFAULT]: `เปิดเช็คลิสต์โดยค่าเริ่มต้นหรือไม่?`,
  [k.WEB_APP]: `แอปพลิเคชันเว็บ`,
  [k.UNIFIZE_LITE_ON_WEB]: `Unifize Lite บนเว็บ`,
  [k.UNIFIZE_LITE_ON_MOBILE_NAVIGA]: `Unifize Lite บนมือถือ (นำทางไปเช็คลิสต์เมื่อเปิดการสนทนา)`,
  [k.DEFAULT_CHECKLIST_WIDTH_ON_UNI]: `ความกว้างเช็คลิสต์เริ่มต้นบน Unifize web app`,
  [k.SINGLE_COLUMN]: `คอลัมน์เดียว`,
  [k.DOUBLE_COLUMN]: `สองคอลัมน์`,
  [k.TRIPLE_COLUMN]: `สามคอลัมน์`,
  [k.SPLIT_AVAILABLE_SCREEN_SPACE_I]: `แบ่งพื้นที่จอ 50% สำหรับการสนทนา และ 50% สำหรับเช็คลิสต์`,
  [k.ONLY_THE_CONTENTS_OF_THE_CONVE1]: `จะซ่อนเฉพาะเนื้อหาของการสนทนา ส่วนข้อมูลอื่นเช่นการแจ้งเตือนยังมองเห็นได้`,
  [k.CONVERSATIONS_WILL_BE_VISIBLE1]: `การสนทนาจะมองเห็นได้เฉพาะผู้เข้าร่วม แอดมิน และรายชื่อที่อนุญาต`,
  [k.PRIVACY_SETTINGS_DON_T_APPLY_T]: `การตั้งค่าความเป็นส่วนตัวไม่มีผลกับผู้ใช้ที่อยู่ในรายการอนุญาต`,
  [k.MAKE_PRIVATE1]: `ตั้งเป็นส่วนตัว`,
  [k.ONLY_PARTICIAPNTS_CAN_ACCESS_T]: `เฉพาะผู้เข้าร่วมเท่านั้นที่เข้าถึงการสนทนาเหล่านี้`,
  [k.PRIVATE_CONVERSATIONS_CAN_ONLY]: `การสนทนาแบบส่วนตัวสามารถแก้ไขได้โดยเจ้าของกระบวนการเท่านั้น`,
  [k.AUTO_REMIND_IN]: `เตือนอัตโนมัติใน`,
  [k.ALLOW_PARTICIPANTS_TO_DISABLE]: `อนุญาตให้ผู้เข้าร่วมปิดการเตือน`,
  [k.REMINDER_MESSAGE]: `ข้อความเตือน`,
  [k.SELECT_VALUE]: `เลือกค่า`,
  [k.ADD_AUTO_REMINDER]: `เพิ่มการเตือนอัตโนมัติ`,
  [k.DESCRIPTION]: `คำอธิบาย`,
  [k.PARTICIPANTS]: `ผู้เข้าร่วม`,
  [k.OWNER]: `เจ้าของ`,
  [k.FORM_OWNERS]: `เจ้าของฟอร์ม`,
  [k.PROCESS_OWNERS]: `เจ้าของกระบวนการ`,
  [k.TAB]: `Tab`,
  [k.NOTIFICATION_SETTINGS]: `การตั้งค่าการแจ้งเตือน`,
  [k.EXIT_PROCESS_EDITOR]: `ออกจากตัวแก้ไขกระบวนการ`,
  [k.ENTER_A_TITLE_FOR_THIS_PROCESS]: `ใส่ชื่อกระบวนการนี้`,
  [k.SAVEPROCESSTEMPLATEBUTTON]: `saveProcessTemplateButton`,
  [k.DEFINE_NEW_PROCESS_TEMPLATE]: `กำหนดแม่แบบกระบวนการใหม่`,
  [k.CREATE_PROCESS]: `สร้างกระบวนการ`,
  [k.SAVE_CHANGES]: `บันทึกการเปลี่ยนแปลง`,
  [k.EXPORT_FIELDS_JSON]: `ส่งออกฟิลด์เป็น JSON`,
  [k.SAVE_AND_GO_TO]: `บันทึกและไปที่`,
  [k.TEAM]: `ทีม`,
  [k.STATUS]: `สถานะ`,
  [k.DELETED_FIELDS]: `ฟิลด์ที่ถูกลบ`,
  [k.ADVANCED_PROCESS_SETTINGS]: `การตั้งค่ากระบวนการขั้นสูง`,
  [k.DEFAULT_FORM_COLOUR]: `สีเริ่มต้นของฟอร์ม`,
  [k.DETAILS]: `รายละเอียด`,
  [k.EXIT_FORM_EDITOR]: `ออกจากตัวแก้ไขฟอร์ม`,
  [k.CREATE_FORM]: `สร้างฟอร์ม`,
  [k.REPORTS]: `รายงาน`,
  [k.FORMS]: `ฟอร์ม`,
  [k._18]: `>`,
  [k.TO]: `ถึง`,
  [k.FILTERS_APPLIED]: `ตัวกรองที่ใช้:`,
  [k.SORTED_BY]: `เรียงโดย:`,
  [k.CLEAR_ALL]: `ล้างทั้งหมด`,
  [k.SHARE]: `แชร์`,
  [k.RESULTS]: `ผลลัพธ์`,
  [k.SAVE_AS]: `บันทึกเป็น`,
  [k.ASCENDING]: `เรียงจากน้อยไปมาก`,
  [k.DESCENDING]: `เรียงจากมากไปน้อย`,
  [k.TO_ARRANGE_DRAG_UP_OR_DOWN]: `ลากขึ้นหรือลงเพื่อจัดเรียง`,
  [k.FILTER_NAME]: `ชื่อตัวกรอง`,
  [k.ORDER]: `ลำดับ`,
  [k.PARAMETERS]: `พารามิเตอร์`,
  [k.SORT_BY2]: `เรียงตาม`,
  [k.FILTER_VALUES]: `ค่าตัวกรอง`,
  [k.SELECT_DATE]: `เลือกวันที่`,
  [k.FILTER_BY]: `กรองโดย`,
  [k.AFTER]: `หลัง`,
  [k.BEFORE]: `ก่อน`,
  [k.FILTER]: `ตัวกรอง`,
  [k.USERFILTER]: `userFilter`,
  [k.THERE_ARE_NO_FORMS_THAT_MATCHE]: `ไม่มีฟอร์มที่ตรงกับตัวกรอง`,
  [k._19]: `_`,
  [k.DELETE_FORM]: `ลบฟอร์ม`,
  [k.FORM_NAME]: `ชื่อฟอร์ม`,
  [k.CREATED_AT]: `สร้างเมื่อ`,
  [k.UPDATED_AT]: `อัปเดตเมื่อ`,
  [k.CREATED_BY1]: `สร้างโดย`,
  [k.NEW]: `ใหม่`,
  [k.MANAGE]: `จัดการ`,
  [k.PROCESSES]: `กระบวนการ`,
  [k.USER_ANALYTICS1]: `การวิเคราะห์ผู้ใช้`,
  [k.EDIT1]: `แก้ไข`,
  [k.DESCRIPTION1]: `คำอธิบาย:`,
  [k.REPORT]: `รายงาน:`,
  [k.CREATE_NEW_CHART]: `สร้างแผนภูมิใหม่`,
  [k.CHARTS_FOR_THIS_REPORT]: `แผนภูมิสำหรับรายงานนี้`,
  [k.MULTIPLE_VALUE_CALCULATION]: `การคำนวณค่าหลายรายการ`,
  [k.IF_A_FIELD_CONTAINS_MULTIPLE_V]: `หากฟิลด์มีหลายค่าในแต่ละการสนทนา ให้เลือกว่าค่าบนแกน Y จะแสดงอะไร`,
  [k.Y_AXIS_SHOWS_TOTAL_CONVERSATIO]: `แกน Y แสดงจำนวนการสนทนา (ให้น้ำหนักตามการสนทนา)`,
  [k.Y_AXIS_SHOWS_TOTAL_VALUE_COUNT]: `แกน Y แสดงจำนวนค่ารวม (ให้น้ำหนักเท่ากันต่อค่า)`,
  [k.PRIMARY_Y_AXIS_LABEL]: `ป้ายกำกับแกน Y หลัก`,
  [k.SECONDARY_Y_AXIS_LABEL]: `ป้ายกำกับแกน Y รอง`,
  [k.CHART_TYPE]: `ประเภทแผนภูมิ`,
  [k.PRIMARY_FIELD]: `ฟิลด์หลัก`,
  [k.PRIMARYRECORD]: `primaryRecord`,
  [k.PRIMARY_RECORD]: `ระเบียนหลัก`,
  [k.MULTIPLE_VALUES]: `หลายค่า`,
  [k.ADD_COMPARISON]: `+ เพิ่มการเปรียบเทียบ`,
  [k.ADD_TRENDLINE]: `เพิ่มเทรนด์ไลน์`,
  [k.SELECT_ABOVE_FIELDS_TO_ENABLE]: `เลือกฟิลด์ด้านบนเพื่อเปิดใช้งาน`,
  [k.TRENDLINE_LABEL]: `ป้ายกำกับเทรนด์ไลน์`,
  [k.TRENDLINE_COLOR]: `สีของเทรนด์ไลน์`,
  [k.HIDE1]: `ซ่อน`,
  [k.OF]: `ของ`,
  [k.SELECT_FIELD]: `เลือกฟิลด์`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_DELET2]: `คุณแน่ใจหรือไม่ว่าต้องการลบ "`,
  [k._20]: `"`,
  [k.GROUP_BY]: `จัดกลุ่มตาม`,
  [k.ADD_TREND_LINE]: `เพิ่มเทรนด์ไลน์`,
  [k.PERCENT]: `เปอร์เซ็นต์`,
  [k.PPM]: `PPM`,
  [k.SHOW_AS_PERCENT_OF_OTHER_VALUE]: `แสดงเป็นเปอร์เซ็นต์ของค่าอื่น`,
  [k.SERIES1]: `ซีรีส์ #`,
  [k.SUMMARY_TYPE]: `ประเภทสรุป`,
  [k.X_AXIS]: `x-axis`,
  [k.UNTITLED_CHART]: `ไม่มีชื่อแผนภูมิ`,
  [k.ADVANCED_SETTINGS]: `การตั้งค่าขั้นสูง`,
  [k.PLEASE_FILL_MANDATORY_FIELDS]: `โปรดกรอกฟิลด์ที่จำเป็น`,
  [k.SUBMIT]: `ส่ง`,
  [k.CHART1]: `แผนภูมิ`,
  [k.SELECT_A_COMPARISON_CHART]: `เลือกแผนภูมิเปรียบเทียบ`,
  [k.FORM1]: `(FORM:`,
  [k.FIELD2]: `) (ฟิลด์:`,
  [k.COMMONCHECKLIST]: `commonChecklist`,
  [k.MULTIPLE_VALUE_FIELD]: `ฟิลด์ค่าหลายรายการ`,
  [k.STATUS1]: `สถานะ`,
  [k.CREATED1]: `สร้างแล้ว`,
  [k.COMPLETED]: `เสร็จสิ้น`,
  [k.ADD_COLUMN]: `เพิ่มคอลัมน์`,
  [k.SELECT_FORM1]: `เลือกฟอร์ม`,
  [k.THE_COUNT_WILL_REPRESENT_THE_T]: `จำนวนจะหมายถึงจำนวนอินสแตนซ์ของฟอร์มนั้น`,
  [k.X_AXIS1]: `(แกน x)`,
  [k.PRIMARY_FIELDS_ARE_THE_MAIN_UN]: `ฟิลด์หลักคือข้อมูลหลักสำหรับการทำแผนภูมิ`,
  [k.GROUP_BY_FIELD]: `ฟิลด์ที่ใช้จัดกลุ่ม`,
  [k.OPTIONAL]: `(ไม่บังคับ)`,
  [k.GROUP_BY_FIELD_TYPE_IS_APPLIED]: `ชนิดการจัดกลุ่มฟิลด์จะใช้กับฟิลด์หลักเพื่อสร้างแผนภูมิ`,
  [k.ADVANCED_SETTINGS1]: `การตั้งค่าขั้นสูง`,
  [k.IF_A_FIELD_CONTAINS_MULTIPLE_V1]: `หากฟิลด์มีหลายค่าในแต่ละการสนทนา ให้เลือกว่าค่าบนแกน Y จะแสดงอะไร`,
  [k.Y_AXIS_LABEL]: `ป้ายกำกับแกน Y`,
  [k.SAVE_CHANGES1]: `บันทึกการเปลี่ยนแปลง`,
  [k.CREATE_CHART]: `สร้างแผนภูมิ`,
  [k.LABEL_FOR_Y_AXIS_OF_THE_CHART]: `ป้ายกำกับสำหรับแกน Y ของแผนภูมิ`,
  [k.IF_A_FIELD_CONTAINS_MULTIPLE_V2]: `หากฟิลด์มีหลายค่าในแต่ละการสนทนา ให้เลือกว่าค่าบนแกน Y จะแสดงอะไร`,
  [k.CREATE_AND_SAVE]: `สร้างและบันทึก`,
  [k.Y_AXIS_SHOWS_TOTAL_CONVERSATIO1]: `แกน Y แสดงจำนวนการสนทนา (ให้น้ำหนักตามการสนทนา)`,
  [k.ENTER_THE_TITLE_OF_YOUR_CHART]: `ใส่ชื่อของแผนภูมิ`,
  [k.NUMERIC_CHECKLIST_FIELD]: `ฟิลด์เช็คลิสต์แบบตัวเลข`,
  [k.THIS_FIELD_WILL_CONTAIN_THE_NU]: `ฟิลด์นี้จะประกอบด้วยข้อมูลตัวเลข เช่น มูลค่าเงิน หรือจำนวนชั่วโมง`,
  [k.IF_A_FIELD_CONTAINS_MULTPLE_VA]: `หากฟิลด์มีหลายค่าในแต่ละการสนทนา ให้เลือกว่าค่าบนแกน Y จะแสดงอะไร`,
  [k.DAILY]: `รายวัน`,
  [k.WEEKLY]: `รายสัปดาห์`,
  [k.MONTHLY]: `รายเดือน`,
  [k.YEARLY]: `รายปี`,
  [k.MMM_DD_YYYY1]: `MMM DD YYYY`,
  [k.LAST]: `ย้อนหลัง`,
  [k.TIME_INTERVAL_FREQUENCY]: `ช่วงเวลา (ความถี่)`,
  [k.THE_INTERVAL_AT_WHICH_THE_NUME]: `ช่วงเวลาที่ค่าตัวเลขด้านบนจะถูกนำมาสร้างแผนภูมิซ้ำ`,
  [k.TIME_PERIOD]: `ช่วงเวลา`,
  [k.SELECT_A_TIME_FRAME_OR_A_DATE]: `เลือกช่วงเวลาหรือช่วงวันที่สำหรับแผนภูมิ`,
  [k.COLUMN1]: `Column`,
  [k.SHOW_A_COLUMN_FOR_EACH_OF_THE]: `แสดงคอลัมน์สำหรับแต่ละค่าใน:`,
  [k.FIELDS_OF_THE_CHART]: `ฟิลด์ของแผนภูมิ`,
  [k.CHART_TITLE]: `ชื่อแผนภูมิ`,
  [k.WHAT_DOES_THIS_CHART_REPRESENT]: `แผนภูมินี้แสดงข้อมูลอะไร?`,
  [k.SELECT_A_CHART_FOR]: `เลือกแผนภูมิสำหรับ`,
  [k.SAVE_AS1]: `บันทึกเป็น`,
  [k.DO_YOU_WANT_TO_DELETE]: `คุณต้องการลบ "`,
  [k.ENTER_REPORT_TITLE]: `ใส่ชื่อรายงาน`,
  [k.SAVE_AS_REPORT]: `บันทึกเป็นรายงาน`,
  [k.EDIT_REPORT_TITLE]: `แก้ไขชื่อรายงาน`,
  [k.EDIT2]: `แก้ไข`,
  [k.REPORT_NAME]: `ชื่อรายงาน`,
  [k.STRING1]: `สตริง`,
  [k.APPLY_BULK_CHANGES]: `ใช้การเปลี่ยนแปลงเป็นกลุ่ม`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_APPLY]: `คุณแน่ใจหรือไม่ว่าต้องการใช้การเปลี่ยนแปลงกับหลายการสนทนา?`,
  [k.REPEAT_LAST_ACTION_TO_ALL]: `ทำซ้ำการกระทำล่าสุดกับทั้งหมด`,
  [k.REPLACE_ALL]: `แทนที่ทั้งหมด`,
  [k.ALL]: `ทั้งหมด`,
  [k.RECORDS]: `ระเบียน`,
  [k.DATE1]: `date`,
  [k.CUSTOMIZE_VIEW]: `ปรับแต่งมุมมอง`,
  [k.DOWNLOAD_AS_CSV]: `ดาวน์โหลดเป็น CSV`,
  [k.DOWNLOAD_AS_XLSX]: `ดาวน์โหลดเป็น XLSX`,
  [k.DOWNLOAD_WITH_FILES]: `ดาวน์โหลดพร้อมไฟล์`,
  [k.DOWNLOAD_AS_JSON_BETA]: `ดาวน์โหลดเป็น JSON (ทดลอง)`,
  [k.YOU_WILL_RECEIVE_AN_EMAIL_ON]: `คุณจะได้รับอีเมลบน`,
  [k.ONCE_THE_IMPORT_IS_DON]: `เมื่อการนำเข้าสำเร็จ.`,
  [k.SUBMIT1]: `ส่ง`,
  [k.UPLOAD_CSV]: `อัปโหลด CSV`,
  [k.SELECT_CSV_FILE]: `เลือกไฟล์ CSV`,
  [k.LOADING_RESULTS]: `กำลังโหลดผลลัพธ์`,
  [k.SHOW_ALL_REVISIONS]: `แสดงรีวิชันทั้งหมด`,
  [k.NEW1]: `ใหม่`,
  [k.CUSTOMIZE_TABLE_VIEW]: `ปรับแต่งมุมมองตาราง`,
  [k.COLUMN_TITLE]: `ชื่อคอลัมน์`,
  [k.RESET]: `รีเซ็ต`,
  [k.APPLY]: `นำไปใช้`,
  [k.SAVEAS]: `saveAs`,
  [k.EMBEDDED_FIELDS1]: `ฟิลด์ที่ฝังตัว`,
  [k.EXPANSION]: `expansion`,
  [k.FILTER_BY_VALUE]: `กรองตามค่า`,
  [k.MONTHS]: `เดือน`,
  [k.YEARS]: `ปี`,
  [k.DATE_RANGE]: `ช่วงวันที่`,
  [k.FORM2]: `แบบฟอร์ม`,
  [k.EXPAND]: `ขยาย`,
  [k.PRIORITY]: `ความสำคัญ`,
  [k.LOW]: `ต่ำ`,
  [k.NORMAL1]: `ปกติ`,
  [k.HIGH]: `สูง`,
  [k.CRITICAL]: `วิกฤต`,
  [k.SELECT_ALL1]: `เลือกทั้งหมด`,
  [k.SELECT_NONE]: `ไม่เลือกเลย`,
  [k.MAPPED_TO]: `เชื่อมโยงกับ`,
  [k.PENDING]: `รอการตอบรับ`,
  [k.COMPLETE]: `เสร็จสิ้น`,
  [k.INCLUDE]: `รวม`,
  [k.ARCHIVED]: `เก็บถาวร`,
  [k.CANCELLED]: `ยกเลิกแล้ว`,
  [k.THERE_ARE_NO_PROCESSES_THAT_MA]: `ไม่มีกระบวนการใดที่ตรงกับตัวกรอง`,
  [k.RESIZING]: `กำลังปรับขนาด "`,
  [k._24]: ``,
  [k.NO_VALUE]: `ไม่มีค่า`,
  [k.CURRENTCHATROOMTITLE]: `currentChatroomTitle`,
  [k.C]: `C`,
  [k.CARETUP]: `caretUp`,
  [k.MAKE_AS_CURRENT]: `ทำให้เป็นปัจจุบัน`,
  [k.ARRAY]: `Array`,
  [k.EDITPENCIL]: `editPencil`,
  [k.NO_DESCRIPTION]: `ไม่มีคำอธิบาย`,
  [k.DISABLED1]: `ปิดใช้งาน`,
  [k.PARENT3]: `หลัก`,
  [k.NO_PARENT]: `ไม่มีหัวข้อหลัก`,
  [k.UPDATE_STATUS]: `อัปเดตสถานะ:`,
  [k.CARETDOWN]: `caretDown`,
  [k.UNTITLED_CONVERSATION]: `การสนทนาไม่มีชื่อ`,
  [k.PAGE]: `หน้า`,
  [k.GO_TO_PAGE]: `| ไปที่หน้า:`,
  [k.BACK_TO_REPORT]: `กลับไปที่รายงาน`,
  [k.CREATE_ANOTHER_CHART]: `สร้างแผนภูมิอีกหนึ่ง`,
  [k.OWNER1]: `owner`,
  [k.DUEDATE]: `dueDate`,
  [k._28]: `↑`,
  [k._29]: `↓`,
  [k.PROCESS_NAME]: `ชื่อกระบวนการ`,
  [k.ACTIVE_INSTANCES]: `อินสแตนซ์ที่ใช้งาน`,
  [k.TOTAL_INSTANCES]: `อินสแตนซ์ทั้งหมด`,
  [k.CREATED_BY2]: `สร้างโดย`,
  [k.EDIT_PROCESS]: `แก้ไขกระบวนการ`,
  [k.DELETE_PROCESS]: `ลบกระบวนการ`,
  [k.DRAFT]: `ร่าง`,
  [k.DELETE_PROCESS1]: `ลบกระบวนการ`,
  [k.ALL_ASSOCIATED_CONVERSATIONS]: `? การสนทนาทั้งหมดที่เกี่ยวข้องจะถูกยกเลิก.`,
  [k.FETCHING_USER_AND_ORG_DETAILS]: `กำลังดึงข้อมูลผู้ใช้และองค์กร`,
  [k.INTEGRATION_NOT_FOUND]: `ไม่พบการผสานการทำงาน!`,
  [k.IS_REQUESTING_PERM]: `กำลังร้องขอสิทธิ์ในการเข้าถึงบัญชี Unifize ของคุณบน`,
  [k.WHAT_CAN]: `สิ่งที่`,
  [k.DO]: `ทำได้?`,
  [k.ALLOW]: `อนุญาต`,
  [k.DENY]: `ปฏิเสธ`,
  [k.HTTPS_CDN_BFLDR_COM_H_O_W]: `https://cdn.bfldr.com/5H442O3W/at/pl546j-7le8zk-6gwiyo/Slack_Mark.svg?auto=webp&format=svg`,
  [k.HTTPS_NEWS_MICROSOFT_COM_WP]: `https://news.microsoft.com/wp-content/uploads/prod/2022/05/Microsoft-logo_rgb_c-gray-768x344.png`,
  [k.SLACK]: `Slack`,
  [k.MICROSOFT]: `Microsoft 365`,
  [k.MICROSOFT_ADMIN]: `Microsoft 365 Admin`,
  [k.CREATE_CONVERSATIONS_ON_UNIFIZ]: `สร้างการสนทนาบน Unifize ในนามของคุณ.`,
  [k.FORWARD_MESSAGES_TO_UNIFIZE_ON]: `ส่งต่อข้อความไปยัง Unifize ในนามของคุณ.`,
  [k.NETWORK_ERROR_LOOKS_LIKE_YOU]: `ข้อผิดพลาดเครือข่าย! ดูเหมือนว่าคุณออฟไลน์ :(`,
  [k.CLICK_HERE_TO_TRY_AGAIN]: `คลิกที่นี่เพื่อลองอีกครั้ง`,
  [k.EMAIL1]: `อีเมล`,
  [k.CREATING]: `กำลังสร้าง`,
  [k.ORG1]: `Org...`,
  [k.YOUR_ORG_IS_READY_YOU_WILL_BE]: `องค์กรของคุณพร้อมแล้ว คุณจะถูกนำทางไปในไม่ช้า.`,
  [k.ERROR_CREATING_YOUR_ORG]: `เกิดข้อผิดพลาดในการสร้างองค์กรของคุณ`,
  [k.SOMEONE_FROM_UNIFIZE_WILL_SOON]: `ทีม Unifize จะติดต่อคุณในไม่ช้า.`,
  [k.ORG_NAME]: `ชื่อองค์กร`,
  [k.EMAIL_ADDRESS]: `ที่อยู่อีเมล`,
  [k.AT_LEAST_CHARACTERS]: `อย่างน้อย 8 อักขระ`,
  [k.A_MIXTURE_OF_LETTERS_AND_NUMBE]: `ประกอบด้วยตัวอักษรและตัวเลขผสมกัน.`,
  [k.INCLUSION_OF_AT_LEAST_ONE_UPPE]: `ประกอบด้วยอักษรตัวพิมพ์ใหญ่อย่างน้อยหนึ่งตัว`,
  [k.CONFIRM_PASSWORD]: `ยืนยันรหัสผ่าน`,
  [k.FIRST_NAME]: `ชื่อจริง`,
  [k.LAST_NAME]: `นามสกุล`,
  [k.PLEASE_VERIFY_YOUR_EMAIL_ADDRE]: `โปรดยืนยันที่อยู่อีเมลของคุณ...`,
  [k.CLICK_ON_THE_LINK_WE_SENT_TO]: `คลิกที่ลิงก์ที่เราส่งไปยัง`,
  [k.TO_CREATE_YOUR_ORG]: `เพื่อสร้างองค์กรของคุณ.`,
  [k.DIDN_T_RECEIVE_AN_EMAIL]: `ไม่ได้รับอีเมลหรือ?`,
  [k.RESEND_EMAIL]: `ส่งอีเมลอีกครั้ง`,
  [k.STEP_OF]: `ขั้นตอนที่ 1 จาก 3`,
  [k.SIGN_UP]: `สมัครใช้งาน`,
  [k.SPEED_UP_YOUR_PROCESSES_BY_X]: `เร่งกระบวนการของคุณได้เร็วขึ้นถึง 3 เท่าใน 30 วัน`,
  [k.STEP_OF1]: `ขั้นตอนที่ 2 จาก 3`,
  [k.CREATE_ACCOUNT]: `สร้างบัญชี`,
  [k.SIGN_IN]: `เข้าสู่ระบบ`,
  [k.YOU_HAVE_ALREADY_USED_THIS_EMA]: `คุณได้ใช้ที่อยู่อีเมลนี้ในการสร้างบัญชีไปแล้ว.`,
  [k.STEP_OF2]: `ขั้นตอนที่ 3 จาก 3`,
  [k.SIGN_OUT]: `ออกจากระบบ`,
  [k.LAST_STEP]: `ขั้นตอนสุดท้าย`,
  [k.INVITE_YOUR_COLLABORATORS]: `, เชิญผู้ร่วมงานของคุณ`,
  [k.NO_MORE_SILOS_INVITE_UP_TO]: `ไม่มีไซโลอีกต่อไป เชิญเพื่อนร่วมงานสูงสุด 4 คนตอนนี้ หรือเชิญเพิ่มภายหลัง.`,
  [k.STAGE_INFO_HEADER]: `ส่วนหัวของข้อมูลขั้น:`,
  [k.STAGE]: `ขั้น:`,
  [k.THIS_LINK_IS_NOT_VALID]: `ลิงก์นี้ไม่ถูกต้อง`,
  [k.NEXT]: `ถัดไป`,
  [k.BY_SIGNING_UP_I_AGREE_TO_THE]: `โดยการสมัคร ฉันยอมรับนโยบายความเป็นส่วนตัวและเงื่อนไขการให้บริการของ Unifize`,
  [k.CENTER_SECTION]: `ส่วนตรงกลาง`,
  [k.RIGHT_SECTION]: `ส่วนด้านขวา`,
  [k.ADD_SECTION]: `+ เพิ่มส่วน`,
  [k.SELECT_FROM_EXISTING_TILES]: `เลือกจากไทล์ที่มีอยู่`,
  [k.CREATE_NEW_TILE]: `สร้างไทล์ใหม่`,
  [k.CONVERSATIONS]: `การสนทนา`,
  [k.MY_CONVERSATION_CHART]: `แผนภูมิการสนทนาของฉัน`,
  [k.START_NEW]: `เริ่มใหม่`,
  [k.PIN_A_DASHBOARD_CHART]: `ปักหมุดแผนภูมิในแดชบอร์ด`,
  [k.NAME2]: `NAME`,
  [k.DO_YOU_WANT_TO_DELETE1]: `คุณต้องการลบ`,
  [k.ADD_NEW_TILE]: `+ เพิ่มไทล์ใหม่`,
  [k.SUB_TITLE]: `คำบรรยาย`,
  [k.FILTERS]: `ตัวกรอง`,
  [k.SELECT_AT_LEAST_ONE]: `(เลือกอย่างน้อยหนึ่ง)`,
  [k.UNREAD]: `ยังไม่ได้อ่าน`,
  [k.I_OWN]: `ฉันเป็นเจ้าของ`,
  [k.BLUECROSS]: `blueCross`,
  [k.EVERYONE]: `ทุกคน`,
  [k.EVERYONE1]: `everyOne`,
  [k.BUTTON_TEXT]: `ข้อความบนปุ่ม`,
  [k.WHEN_EMPTY]: `เมื่อว่างเปล่า`,
  [k.TILE_COLOR]: `สีของไทล์`,
  [k.SELECT_A_CHART]: `เลือกแผนภูมิ`,
  [k.DON_T_SHOW_TILE]: `ไม่ต้องแสดงไทล์`,
  [k.SHOW_A_MESSAGE]: `แสดงข้อความ`,
  [k.EXCLUDE_PROCESS]: `ยกเว้นกระบวนการ`,
  [k.INCLUDE_PROCESS]: `รวมกระบวนการ`,
  [k.COLUMNS]: `คอลัมน์`,
  [k.OVERDUE]: `ล่าช้า`,
  [k.TILE_TYPE_CONVERSATIONS]: `ประเภทไทล์: การสนทนา`,
  [k.TILE_TYPE_MY_CONVERSATION_CHA]: `ประเภทไทล์: แผนภูมิการสนทนาของฉัน`,
  [k.TILE_TYPE_START_NEW]: `ประเภทไทล์: เริ่มใหม่`,
  [k.TILE_TYPE_DASHBOARD_CHART]: `ประเภทไทล์: แผนภูมิในแดชบอร์ด`,
  [k.SECTION1]: `ส่วน:`,
  [k.ADD_USER_SEGMENT]: `+ เพิ่มกลุ่มผู้ใช้`,
  [k.AND_USER]: `และผู้ใช้`,
  [k.SHOW_TO]: `แสดงแก่`,
  [k.AND_USER1]: `+ และผู้ใช้`,
  [k.AGE1]: `อายุ`,
  [k.SELECT_TIMEPERIOD]: `เลือกช่วงเวลา`,
  [k.SELECTED]: `ที่เลือก`,
  [k.SELECT_GROUP]: `เลือกกลุ่ม`,
  [k.SELECT_VALUES]: `เลือกค่า`,
  [k.CREATE_NEW_USER_SEGMENT]: `สร้างกลุ่มผู้ใช้ใหม่`,
  [k.OTHERS1]: `คนอื่นๆ`,
  [k.ENABLE_HOME_SCREEN]: `เปิดใช้งานหน้าหลัก`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_DISAB]: `คุณแน่ใจหรือไม่ว่าต้องการปิดใช้งาน`,
  [k.INTEGRATION]: `การผสาน`,
  [k.DISABLE]: `ปิดใช้งาน`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_DISCO]: `คุณแน่ใจหรือไม่ว่าต้องการยกเลิกการเชื่อมต่อ`,
  [k.INTEGRATION_YOU_COULD]: `การผสานอาจทำให้สูญเสียข้อมูลที่เข้าถึงหรือโฮสต์ผ่าน`,
  [k.YOU_CANNOT_UNDO_THIS_ACTION_C]: `ไม่สามารถย้อนกลับการกระทำนี้ได้ โปรดพิจารณาปิดใช้งานแทน.`,
  [k.DISCONNECT]: `ยกเลิกการเชื่อมต่อ`,
  [k.HEADING]: `หัวข้อ`,
  [k.INTEGRATION1]: `การผสาน`,
  [k.INTEGRATE]: `ผสาน`,
  [k.GLOBAL_OR_PRIVILEGED_ADMINIST]: `*เฉพาะผู้ดูแลระบบระดับโลกหรือผู้ดูแลระบบระดับสูง`,
  [k.NO_APPS_FOUND]: `ไม่พบแอปพลิเคชัน`,
  [k.ORG_SETTINGS]: `การตั้งค่าองค์กร`,
  [k.HOME_SCREEN1]: `หน้าหลัก`,
  [k.ORG_DETAILS1]: `รายละเอียดองค์กร`,
  [k.ROLE_MANAGEMENT1]: `การจัดการบทบาท`,
  [k.APPS]: `แอปพลิเคชัน`,
  [k.DELETE_ROLE]: `ลบบทบาท`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_DELET3]: `คุณแน่ใจหรือไม่ว่าต้องการลบบทบาทนี้?`,
  [k.DELETE_ORG_DOMAIN]: `ลบโดเมนขององค์กร`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_DELET4]: `คุณแน่ใจหรือไม่ว่าต้องการลบโดเมนนี้ออกจากองค์กร?`,
  [k.ORG_DOMAIN]: `โดเมนองค์กร`,
  [k.EMAIL_PREFIX]: `คำนำหน้าชื่ออีเมล`,
  [k.ALLOW_CLONING]: `อนุญาตให้ทำสำเนา`,
  [k.ORG_DESCRIPTION]: `คำอธิบายองค์กร`,
  [k.ORG_DESCRIBER_VIDEO_PASTE_IFR]: `วิดีโออธิบายองค์กร (วางโค้ด iframe)`,
  [k.ADD_USER]: `เพิ่มผู้ใช้`,
  [k.MEMBERS1]: `สมาชิก`,
  [k.ADD_USERS]: `+ เพิ่มผู้ใช้`,
  [k.ADD_A_NEW_ROLE]: `+ เพิ่มบทบาทใหม่`,
  [k.FUNCTIONALITY]: `ฟังก์ชัน`,
  [k.SERVICE_PROVIDER_ENTITY_ID]: `รหัสองค์กรผู้ให้บริการ`,
  [k.AUTHORIZATION_CALLBACK_URL]: `URL การเรียกกลับสำหรับการอนุญาต`,
  [k.HUGE_PARAGRAPH_HERE]: `(ข้อความยาวใหญ่)`,
  [k.YES_LOGOUT_ALL_USERS]: `ใช่, ออกจากระบบผู้ใช้ทั้งหมด`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_DISAB1]: `คุณแน่ใจหรือไม่ว่าต้องการปิดใช้งาน SSO?`,
  [k.ENTER_NEW_PASSWORD]: `ใส่รหัสผ่านใหม่`,
  [k.CONFIRM_NEW_PASSWORD]: `ยืนยันรหัสผ่านใหม่`,
  [k.DISABLE_SSO]: `ปิดใช้งาน SSO`,
  [k.DOMAIN1]: `โดเมน`,
  [k._31]: `*`,
  [k.ENTITY_ID]: `รหัสองค์กร`,
  [k.SSO_URL]: `URL SSO`,
  [k.CERTIFICATE]: `ใบรับรอง`,
  [k.ADD_CERTIFICATE]: `เพิ่มใบรับรอง`,
  [k.MUST_START_WITH_BEGIN_CE]: `ต้องขึ้นต้นด้วย '-----BEGIN CERTIFICATE-----' และลงท้ายด้วย '-----END CERTIFICATE-----'`,
  [k.PAGE_NOT_FOUND]: `404 ไม่พบหน้านี้`,
  [k.WELCOME_BACK1]: `ยินดีต้อนรับกลับ`,
  [k._32]: `!`,
  [k.LOG_IN]: `เข้าสู่ระบบ`,
  [k.TO_USE_THE_FULL]: `เพื่อใช้งานแอปในเวอร์ชันเต็ม`,
  [k.LOG_IN1]: `เข้าสู่ระบบ`,
  [k.SIGN_UP1]: `สมัครใช้งาน`,
  [k.DRIVE_PROCESS_THROUGH_CONVERSA]: `ขับเคลื่อนกระบวนการผ่านการสนทนา`,
  [k.UNIFIZE_INCREASES_YOUR_TEAM]: `Unifize ช่วยเพิ่มประสิทธิภาพของทีมคุณ`,
  [k.S_EFFICIENCY_BY_UP_TO_X_BY]: `ได้มากถึง 10 เท่า โดยผสานการสนทนาเข้ากับเป้าหมายของกระบวนการ`,
  [k.SIGN_UP_FAILED]: `การสมัครล้มเหลว!`,
  [k.CLICK_HERE_TO]: `คลิกที่นี่เพื่อ`,
  [k.RESEND1]: `ส่งอีกครั้ง`,
  [k.VERIFICATION_EMAIL_SENT]: `ส่งอีเมลยืนยันแล้ว`,
  [k.AN_EMAIL_HAS_BEEN_SENT_TO]: `ได้ส่งอีเมลไปยัง`,
  [k.PLEASE_OPEN_YOUR_EMA]: `. โปรดเปิดอีเมลและคลิกที่ลิงก์เพื่อเริ่มใช้งาน Unifize`,
  [k.OR]: `หรือ`,
  [k.CHATROOM_HEADER]: `Chatroom Header`,
  [k.CLICK_HERE_TO_SIGN_UP_OR_LOG_I]: `คลิกที่นี่เพื่อสมัครหรือเข้าสู่ระบบ`,
  [k.HEADER]: `header`,
  [k.INPUT]: `input`,
  [k.YOU_NEED_TO_LOG_IN_TO_DO_THAT]: `คุณต้องเข้าสู่ระบบก่อนเพื่อทำการนี้`,
  [k.HISTORY]: `ประวัติ`,
  [k.PANEL]: `panel`,
  [k.PLUSCIRCLE]: `plusCircle`,
  [k.FILE]: `ไฟล์`,
  [k.MANAGE_FILE_VIEW]: `manage-file-view`,
  [k.CREATEPROCESSTEMPLATE]: `createProcessTemplate`,
  [k.REMOVECIRCLE]: `removeCircle`,
  [k.CLEAR_ALL1]: `ล้างทั้งหมด`,
  [k.OPTION_NOT_FOUND]: `ไม่พบตัวเลือก`,
  [k.ADD_NEW_OPTION]: `เพิ่มตัวเลือกใหม่`,
  [k.NEW_OPTION]: `ตัวเลือกใหม่`,
  [k.EMAIL_NOTIFICATIONS]: `การแจ้งเตือนทางอีเมล`,
  [k.SELECT_YOUR_PREFERED_NOTIFICAT]: `เลือกการตั้งค่าการแจ้งเตือนที่คุณต้องการ`,
  [k.NOTIFICATIONS]: `การแจ้งเตือน`,
  [k.EVERYTHING]: `ทั้งหมด:`,
  [k.ALL_UPDATES_AND_MESSAGES]: `อัปเดตและข้อความทั้งหมด`,
  [k.FOR_CONVERSATIONS_YOU_ARE_A_PA]: `สำหรับการสนทนาที่คุณเป็นส่วนหนึ่ง`,
  [k.YOU_WILL_BE_NOTI]: `, คุณจะได้รับอีเมล`,
  [k.ALL_COMMENTS]: `ความคิดเห็นทั้งหมด`,
  [k.AND_MESSAGES]: `และข้อความ`,
  [k.YOU_VE_BEEN_ADDED]: `คุณถูกเพิ่ม`,
  [k.TO_A_CONVERSATION]: `เข้าสู่การสนทนา`,
  [k.ALL_STATUS_UPDATES]: `อัปเดตสถานะทั้งหมด,`,
  [k.INCLUDING_WHEN_CONVERSATIONS]: `รวมถึงเมื่อการสนทนาสิ้นสุด`,
  [k.WHEN_THE_CONVERSATION_IS]: `เมื่อการสนทนา`,
  [k.OVERDUE1]: `ล่าช้า`,
  [k.AND_OTHER]: `และอื่น ๆ`,
  [k.ANY_OWNER_CHANGE]: `การเปลี่ยนเจ้าของใด ๆ`,
  [k.OR_ASSIGNMENT]: `หรือการมอบหมาย`,
  [k.WHEN_YOU_VE_BEEN]: `เมื่อคุณถูก`,
  [k.MENTIONED]: `@กล่าวถึง`,
  [k.IN_A_CONVERSATION]: `ในบทสนทนา`,
  [k.IMPORTANT]: `สำคัญ:`,
  [k.STATUS_UPDATES_REMINDERS_AND]: `สถานะอัปเดต, การแจ้งเตือน, และ @กล่าวถึง`,
  [k.FOR_CONVERSATIONS_YOU_ARE_A_PA1]: `สำหรับการสนทนาที่คุณเป็นส่วนหนึ่ง,`,
  [k.YOU_WILL_BE_NOTIFI]: `คุณจะได้รับอีเมล`,
  [k.ALL_STATUS_UPDATES1]: `อัปเดตสถานะทั้งหมด`,
  [k.INCLUDING_WHEN_CONVERSATIONS1]: `, รวมถึงเมื่อการสนทนาสิ้นสุด`,
  [k.AND_OTHER_REMIND]: `และการแจ้งเตือนอื่น ๆ`,
  [k.CRITICAL1]: `สำคัญ:`,
  [k.CRITICAL_UPDATES_AND_MENTIONS]: `อัปเดตที่สำคัญและการ @กล่าวถึง`,
  [k.YOU_VE_BEEN_ASSIGNED_AS_THE_OW]: `คุณถูกกำหนดเป็นเจ้าของ`,
  [k.OF_A_CONVERSATIO]: `ของการสนทนา`,
  [k.YOU_VE_BEEN]: `คุณถูก`,
  [k.NOTHING_NOT_RECOMMENDED]: `ไม่มีเลย (ไม่แนะนำ)`,
  [k.YOU_WILL_NOT_BE_NOTIFIED_VIA_E]: `คุณจะไม่ได้รับการแจ้งเตือนทางอีเมล ซึ่งอาจพลาดข้อมูลสำคัญได้`,
  [k.SORT]: `เรียงลำดับ`,
  [k.FILTER_VALUES1]: `ค่าตัวกรอง`,
  [k.SEARCH1]: `ค้นหา`,
  [k.BLUETICK]: `blueTick`,
  [k.CREATE_NEW_STATUS]: `+ สร้างสถานะใหม่`,
  [k.ENTER_STATUS_TITLE]: `ใส่ชื่อสถานะ`,
  [k.ACTIVERADIOBUTTON]: `activeRadioButton`,
  [k.DEFAULTRADIOBUTTON]: `defaultRadioButton`,
  [k.MAP_TO_PENDING]: `เชื่อมโยงกับรอการตอบรับ`,
  [k.MAP_TO_COMPLETE]: `เชื่อมโยงกับเสร็จสิ้น`,
  [k.DISABLE_UNTIL_APPROVED]: `ปิดใช้งานจนกว่าจะอนุมัติ`,
  [k.DELETETRASH]: `deleteTrash`,
  [k.CIRCLEDOWN]: `circleDown`,
  [k.HIDE_UNTIL_CONDITION_MET]: `ซ่อนจนกว่าเงื่อนไขจะเป็นจริง`,
  [k.ENABLE_UNTIL_CONDITION_MET]: `เปิดใช้งานจนกว่าเงื่อนไขจะเป็นจริง`,
  [k.STATUS_TYPE]: `ประเภทสถานะ`,
  [k.ARCHIVE_BEHAVIOR]: `พฤติกรรมการเก็บ`,
  [k.IGNORE_ARCHIVING]: `ไม่สนใจการเก็บ`,
  [k.AUTO_UNARCHIVE]: `ยกเลิกการเก็บอัตโนมัติ`,
  [k.STATUS_SETTINGS]: `การตั้งค่าสถานะ`,
  [k.YOU_NEED_TO_HAVE_AT_LEAST_ONE]: `คุณต้องมีอย่างน้อยหนึ่งสถานะที่เชื่อมโยงกับ`,
  [k.PENDING2]: `รอการตอบรับ`,
  [k.AND]: `และ`,
  [k.COMPLETED1]: `เสร็จสิ้น`,
  [k.EACH]: `แต่ละรายการ.`,
  [k.OK]: `ตกลง`,
  [k.ACTIVECHECKBOX]: `กล่องเลือกที่เปิดใช้งาน`,
  [k.DEFAULTCHECKBOX]: `กล่องเลือกเริ่มต้น`,
  [k.SELECTED_ITEM]: `รายการที่เลือก-`,
  [k._34]: `✕`,
  [k.UPDATE_HAS_BEEN_RELASED]: `มีการอัปเดตใหม่`,
  [k.THERE_IS_A_NEW_VERSION_OF_THE]: `มีเวอร์ชันใหม่ของแอปพร้อมใช้งาน คลิกรีเฟรชเพื่อดู`,
  [k.ACCESS_DENIED]: `ปฏิเสธการเข้าถึง!!`,
  [k.CONTACT_US]: `ติดต่อเรา`,
  [k.LOOKS_LIKE_YOUR_EMAIL_IS_NOT_V]: `ดูเหมือนว่าอีเมลของคุณยังไม่ได้รับการยืนยัน`,
  [k.AN_EMAIL_HAS_BEEN_SENT_TO_YOUR]: `เราได้ส่งอีเมลไปยังบัญชีของคุณแล้ว โปรดเปิดอีเมลและคลิกที่ลิงก์`,
  [k.STILL_HAVING_TROUBLES]: `ยังคงพบปัญหาอยู่หรือ?`,
  [k.FORGOT_YOUR_PASSWORD]: `ลืมรหัสผ่านหรือ?`,
  [k.ENTER_THE_EMAIL_ADDRESS_YOU_US]: `ใส่ที่อยู่อีเมลที่คุณใช้ คุณจะได้รับอีเมลพร้อมลิงก์รีเซ็ตรหัสผ่านภายในไม่กี่นาที`,
  [k.SEND_LINK]: `ส่งลิงก์`,
  [k.PASSWORD1]: `รหัสผ่าน`,
  [k.LOADING1]: `กำลังโหลด`,
  [k.LOADING_CONVERSATIONS_FOR]: `กำลังโหลดการสนทนาสำหรับ`,
  [k.UNABLE_TO_LOGIN]: `ไม่สามารถเข้าสู่ระบบ -`,
  [k.CONTINUE_IN_APP]: `ใช้งานต่อในแอป`,
  [k.HEY_THERE_LOOKS_LIKE_YOU_RE_T]: `สวัสดี! ดูเหมือนว่าคุณกำลังเข้าสู่ระบบจากมือถือ`,
  [k.CONTINUE_WITH_UNIFIZE_MOBILE_A]: `ใช้งานต่อด้วย Unifize Mobile App`,
  [k.ABOUT_US]: `เกี่ยวกับเรา`,
  [k.RESET_YOUR_PASSWORD]: `รีเซ็ตรหัสผ่านของคุณ`,
  [k.YOU_HAVE_CLICKED_ON_A_PASSWORD]: `คุณคลิกที่ลิงก์รีเซ็ตรหัสผ่านสำหรับ:`,
  [k.ENTER_A_NEW_PASSWORD]: `ใส่รหัสผ่านใหม่`,
  [k.RESET_MY_PASSWORD]: `รีเซ็ตรหัสผ่านของฉัน`,
  [k.PASSWORD_RESET]: `รีเซ็ตรหัสผ่าน`,
  [k.RESET_LINK_SENT]: `ส่งลิงก์รีเซ็ตแล้ว`,
  [k.RESET_INSTRUCTIONS_HAVE_BEEN_S]: `ได้ส่งวิธีรีเซ็ตรหัสผ่านไปยัง`,
  [k.YOU_WILL_RECEIVE]: `. คุณจะได้รับอีเมลภายใน 5 นาที อีเมลอาจอยู่ในโฟลเดอร์สแปมของคุณ.`,
  [k.RESENT_INSTRUCTIONS]: `ส่งคำแนะนำอีกครั้ง`,
  [k.PASSWORD_CHANGE_SUCCESSFUL]: `เปลี่ยนรหัสผ่านสำเร็จ`,
  [k.YOUR_PASSWORD_HAS_BEEN_RESET_S]: `รหัสผ่านของคุณถูกรีเซ็ตเรียบร้อยสำหรับ:`,
  [k.CONTINUE_TO_YOUR_UNIFIZE_APP]: `ไปยัง Unifize App ของคุณ`,
  [k.SERVER_ERROR]: `ข้อผิดพลาดเซิร์ฟเวอร์`,
  [k.RELOAD]: `รีโหลด`,
  [k.LOGOUT]: `ออกจากระบบ`,
  [k.XL]: `2xl`,
  [k.FORGOT_PASSWORD]: `ลืมรหัสผ่าน?`,
  [k.DON]: `Don`,
  [k.T_HAVE_AN_ACCOUNT]: `t มีบัญชีหรือ?`,
  [k.SIGN_UP_NOW]: `สมัครตอนนี้!`,
  [k.THIS_EMAIL_ADDRESS_IS_NOT_VERI]: `ที่อยู่อีเมลนี้ยังไม่ได้รับการยืนยัน`,
  [k.THE_USERNAME_OR_PASSWORD_IS_IN]: `ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง`,
  [k.TOO_MANY_FAILED_LOGIN_ATTEMPTS]: `พยายามเข้าสู่ระบบล้มเหลวหลายครั้ง โปรดลองใหม่ภายหลัง`,
  [k.SOMETHING_WENT_WRONG_TRY_AGAI]: `เกิดข้อผิดพลาดบางอย่าง โปรดลองอีกครั้งภายหลัง`,
  [k.PLEASE_CHECK_YOUR_INBOX]: `โปรดตรวจสอบกล่องอีเมลของคุณ`,
  [k.VERIFYING_YOUR_LINK]: `กำลังยืนยันลิงก์ของคุณ`,
  [k.NO_CHARTS_FOUND]: `ไม่พบแผนภูมิ`,
  [k.REFRESH1]: `รีเฟรช`,
  [k.SELECT_FIELD1]: `เลือกฟิลด์`,
  [k.TASKLIST]: `รายการงาน`,
  [k.MANDATORY1]: `บังคับ`,
  [k.MANDATORY_FIELD_REMAINING]: `ฟิลด์บังคับที่เหลือ`,
  [k.OPEN_CHECKLIST]: `เปิดเช็คลิสต์`,
  [k.CARETLEFTFILLED]: `caretLeftFilled`,
  [k.CLICK_TO_ADD_DESCRIPTION]: `คลิกเพื่อเพิ่มคำอธิบาย`,
  [k.VIEW_IN_ACTIVE_CHAT]: `ดูในการสนทนาปัจจุบัน`,
  [k.UNABLE_TO_PERFORM_ACTION]: `ไม่สามารถดำเนินการได้`,
  [k.YOU_SHOULD_NAVIGATE_TO_CONVERS]: `คุณควรไปที่หน้าการสนทนาเพื่อทำการนี้`,
  [k.VIEW_IN_ACTIVE_CHAT1]: `ดูในการสนทนาปัจจุบัน`,
  [k.TO_PERFORM_THIS_ACTION]: `เพื่อดำเนินการนี้`,
  [k.CONVERSATION_DIALOG]: `conversation-dialog`,
  [k.CONVERSATION_DIALOG1]: `#conversation-dialog`,
  [k.DATEACTIVE]: `dateActive2`,
  [k.DATEACTIVE1]: `dateActive`,
  [k.FILES_FROM_UNIFIZE_CONVERSATIO]: `ไฟล์จากการสนทนาใน Unifize`,
  [k.ATTACH_FILES_FROM_YOUR_COMPUTE]: `แนบไฟล์จากคอมพิวเตอร์ของคุณ`,
  [k.CC]: `Cc`,
  [k.BCC]: `Bcc`,
  [k.SUBJECT]: `หัวข้อ`,
  [k.SEND_AS_EMAIL]: `ส่งเป็นอีเมล`,
  [k.DISCARD]: `ยกเลิก`,
  [k.SEND]: `ส่ง`,
  [k.FILES_IN_CHATROOM]: `ไฟล์ในห้องสนทนา`,
  [k.ACCESS_REQUEST]: `คำขอการเข้าถึง`,
  [k.PEOPLE_HAVE_REQUESTED_TO_ACCES]: `คนต้องการขอเข้าถึงการสนทนาแบบส่วนตัวนี้`,
  [k.ACCEPT]: `ยอมรับ`,
  [k.PEOPLE]: `คน`,
  [k.HAVE_REQUESTED_TO]: `ได้ขอเข้าร่วมการสนทนา`,
  [k.RESPOND]: `ตอบกลับ`,
  [k.HAS_REQUESTED_TO_JOIN]: `ได้ขอเข้าร่วมการสนทนา`,
  [k.FAVOURITE]: `โปรดปราน`,
  [k._35]: `;`,
  [k.CHANGE_PROCESS_TYPE]: `เปลี่ยนประเภทกระบวนการ`,
  [k.TYPE2]: `ประเภท-`,
  [k.DAYS_OLD]: `วันที่ผ่านมา`,
  [k.LESS_THAN_A_DAY_OLD]: `น้อยกว่า 1 วัน`,
  [k.LINK_COPIED]: `คัดลอกลิงก์แล้ว`,
  [k.CONVERSATION_LINK_HAS_BEEN_COP]: `ลิงก์ของการสนทนาถูกคัดลอกแล้ว`,
  [k.CONVERSATION_LINK]: `ลิงก์การสนทนา`,
  [k.DOWNLOAD_JSON]: `ดาวน์โหลด JSON`,
  [k.FILES1]: `ไฟล์ (`,
  [k.NO_RESULTS_FOR]: `ไม่พบผลลัพธ์สำหรับ`,
  [k.EMAIL_ADDRESS_COPIED]: `คัดลอกที่อยู่อีเมลแล้ว`,
  [k.USE_COPIED_EMAIL_ADDRESS_TO_FO]: `ใช้ที่อยู่อีเมลที่คัดลอกเพื่อส่งต่ออีเมลไปยังการสนทนานี้`,
  [k.FORWARD_MAIL_HERE]: `ส่งต่ออีเมลที่นี่`,
  [k.PRIVACY]: `ความเป็นส่วนตัว`,
  [k.HIDE_EVERYTHING1]: `(ซ่อนทั้งหมด)`,
  [k.HIDE_ONLY_CHAT_CONTENT1]: `(ซ่อนเฉพาะเนื้อหาการสนทนา)`,
  [k.PROCESS_BUILDER]: `ผู้สร้างกระบวนการ`,
  [k.RELATED_CONVERSATIONS]: `การสนทนาที่เกี่ยวข้อง (`,
  [k.MOREMENUFILLED]: `moreMenuFilled`,
  [k.MOREMENU]: `เมนูเพิ่มเติม`,
  [k.MORE2]: `เพิ่มเติม`,
  [k.V]: `V`,
  [k.REVISIONS1]: `รุ่น`,
  [k.CHAT_INPUT]: `ข้อความสนทนา`,
  [k.PLUS]: `บวก`,
  [k.PLUSCHATICON]: `ไอคอนบวกสนทนา`,
  [k.D]: `d`,
  [k.FORWARD_TO]: `ส่งต่อไปยัง`,
  [k.FORWARD]: `ส่งต่อ`,
  [k.UPLOAD_IMAGE]: `อัปโหลดรูปภาพ`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_UPLOA]: `คุณแน่ใจหรือไม่ว่าต้องการอัปโหลดรูปภาพนี้?`,
  [k.OWNER2]: `เจ้าของ`,
  [k.SELECTED1]: `ที่เลือก`,
  [k.RECENT]: `ล่าสุด`,
  [k.ADD_LINK]: `เพิ่มลิงก์`,
  [k.ENTER_VALID_URL]: `ใส่ URL ที่ถูกต้อง`,
  [k.MENTIONBUTTON]: `mentionButton`,
  [k.NO_RESULTS_FOUND]: `ไม่พบผลลัพธ์`,
  [k.EVERY_PARTICIPANT_OF_THE_CONV]: `(ทุกคนที่เข้าร่วมในการสนทนา)`,
  [k.CURRENT_OWNER_OF_THE_CONVERSA]: `(เจ้าของปัจจุบันของการสนทนา)`,
  [k.CREATOR_OF_THE_CONVERSATION]: `(ผู้สร้างการสนทนา)`,
  [k.LOADING_MESSAGES]: `กำลังโหลดข้อความ...`,
  [k.JOIN]: `เข้าร่วม`,
  [k.FULL_NAME_OPTIONAL]: `ชื่อ-นามสกุล (ไม่บังคับ)`,
  [k.EMAIL_ADDRESS1]: `ที่อยู่อีเมล`,
  [k.SELECT_ROLE]: `เลือกบทบาท`,
  [k.SEND_INVITE]: `ส่งคำเชิญ`,
  [k.ADD_PARTICIPANTS_OR_GROUPS]: `เพิ่มผู้เข้าร่วมหรือกลุ่ม`,
  [k.NAME_EMAIL_OR_GROUP]: `ชื่อ, อีเมล หรือกลุ่ม`,
  [k.INVITE]: `เชิญ "`,
  [k.NO_RESULTS_FOR1]: `ไม่พบผลลัพธ์สำหรับ "`,
  [k.TO_INVITE_SOMEONE_EXTERNALLY]: `หากต้องการเชิญบุคคลภายนอก โปรดใช้อีเมลของพวกเขา เช่น`,
  [k.JOHN_DOE_ACME_COM]: `john.doe@acme.com`,
  [k.TO_CONVERSATION]: `สู่การสนทนา`,
  [k.SEND_INVITES]: `ส่งคำเชิญ`,
  [k.PARTICIPANTS1]: `ผู้เข้าร่วม`,
  [k.ADD_PARTICIPANTS_OR_GROUPS1]: `+ เพิ่มผู้เข้าร่วมหรือกลุ่ม`,
  [k.PARTICIPANT]: `ผู้เข้าร่วม`,
  [k.CURVE]: `เส้นโค้ง`,
  [k.DELETED_USER]: `ผู้ใช้ที่ถูกลบ`,
  [k.CHAT_WITH]: `สนทนากับ`,
  [k.START_RELATED_CONVERSATION]: `เริ่มการสนทนาที่เกี่ยวข้อง`,
  [k.OTHER]: `อื่น ๆ`,
  [k.ADD_TO_CONVERSATION]: `เพิ่มเข้าสู่การสนทนา`,
  [k.MEMBER1]: `สมาชิก`,
  [k.UPLOAD_FILES]: `อัปโหลดไฟล์`,
  [k.FROM_PRIVATE_CONVERSATION]: `จากการสนทนาแบบส่วนตัว`,
  [k.START_PROCESS]: `เริ่มกระบวนการ`,
  [k.REMOVE_OWNER]: `ลบเจ้าของ`,
  [k.INVITE1]: `เชิญ`,
  [k.INVITE_VIA_EMAIL]: `เชิญผ่านอีเมล`,
  [k.ASSIGN_OWNER]: `กำหนดเจ้าของ`,
  [k.NOOWNERACTIVE]: `noOwnerActive`,
  [k.NOOWNER]: `noOwner`,
  [k.ASSIGN_OWNER1]: `กำหนดเจ้าของ`,
  [k.NO_OWNER]: `ไม่มีเจ้าของ`,
  [k.MESSAGE]: `ข้อความ`,
  [k.SET_PRIORITY]: `กำหนดความสำคัญ`,
  [k.SET_DUE_DATE]: `กำหนดวันครบกำหนด`,
  [k.SET_PRIORITY_FOR_CONVERSATION]: `กำหนดความสำคัญให้กับการสนทนานี้`,
  [k.BOLD]: `bold`,
  [k.GRAY2]: `gray.200`,
  [k.PRIORITY2]: `Priority:`,
  [k.FAILED_TO_UPLOAD]: `อัปโหลดล้มเหลว`,
  [k.UPLOADING]: `กำลังอัปโหลด`,
  [k.RESTRICTED_ACCESS]: `การเข้าถึงถูกจำกัด`,
  [k.THE_CONVERSATION_YOU_ARE_TRYIN]: `การสนทนาที่คุณพยายามเข้าถึงไม่เปิดให้บัญชีของคุณ โปรดติดต่อผู้ดูแลองค์กร`,
  [k.FAILED_TO_LOAD_THE_CHATROOM]: `ไม่สามารถโหลดห้องสนทนา`,
  [k.CONVERSATION_NOT_FOUND]: `ไม่พบการสนทนา`,
  [k.DEFINE_NEW]: `กำหนดใหม่`,
  [k.CHATROOMSEARCH]: `chatroomSearch`,
  [k.X3]: `X`,
  [k.ADDITIONAL_COMMENTS]: `ความคิดเห็นเพิ่มเติม`,
  [k.SKIP]: `ข้าม`,
  [k.ARCHIVE]: `เก็บถาวร`,
  [k.THIS_CONVERSATION]: `การสนทนานี้`,
  [k.ARCHIVE1]: `เก็บถาวร`,
  [k.CROP_UPLOAD]: `คร็อป & อัปโหลด`,
  [k.FILEINPUT]: `fileInput`,
  [k.BOOLEAN]: `บูลีน`,
  [k.DETAILS_OBJECTIVE]: `รายละเอียด / วัตถุประสงค์`,
  [k.DUE_DATE]: `วันครบกำหนด`,
  [k.START_DIRECT_CONVERSATION]: `เริ่มการสนทนาโดยตรง`,
  [k.YOU_CANT_CHAT_WITH_YOURSELF]: `คุณไม่สามารถสนทนากับตนเองได้`,
  [k.CHOOSE_USER_TO_CHAT_WITH]: `เลือกผู้ใช้ที่ต้องการสนทนาด้วย`,
  [k.TO_INVITE_SOMEONE_EXTERNALLY1]: `หากต้องการเชิญบุคคลภายนอก โปรดใช้อีเมลของพวกเขา เช่น john.doe@acme.com`,
  [k.PARENTS]: `ผู้ปกครอง`,
  [k.CHILDREN]: `เด็ก`,
  [k.OVERDUE2]: `เกินกำหนด (`,
  [k.COMPLETED2]: `เสร็จสิ้น (`,
  [k.I]: `ฉัน`,
  [k.M_OWNER]: `เจ้าของ (`,
  [k.RELATED_CONVERSATIONS1]: `การสนทนาที่เกี่ยวข้อง`,
  [k.RELATED_CHILDREN]: `เด็กที่เกี่ยวข้อง (`,
  [k.RELATED_PARENTS]: `ผู้ปกครองที่เกี่ยวข้อง (`,
  [k.SHOWING]: `กำลังแสดง`,
  [k.OPTION]: `ตัวเลือก`,
  [k.I_M_THE_OWNER]: `ฉันเป็นเจ้าของ`,
  [k.RELATED]: `เกี่ยวข้อง`,
  [k.DATEWARNING]: `คำเตือนวันที่`,
  [k.COMPLETED3]: `เสร็จสิ้น`,
  [k.INCOMPLETE1]: `ไม่เสร็จสิ้น`,
  [k.RELATED1]: `เกี่ยวข้อง`,
  [k.NO_LONGER_MATCHES_THE_LIST_F]: `ไม่ตรงกับรายการ / ตัวกรองอีกต่อไป`,
  [k.SEND_A_DIRECT_MESSAGE]: `ส่งข้อความโดยตรง`,
  [k.START_WITH_THIS_SOLUTION]: `เริ่มด้วยโซลูชันนี้`,
  [k.NO_MATCHING_RESULTS1]: `ไม่พบผลลัพธ์ที่ตรงกัน`,
  [k.THERE_WEREN_T_ANY_CONVERSATION]: `ไม่มีการสนทนาใดที่ตรงกับตัวกรองของคุณ`,
  [k.TRY_WIDENING_YOUR_SEARCH]: `ลองขยายขอบเขตการค้นหา`,
  [k.CONGRATULATIONS]: `ขอแสดงความยินดี!`,
  [k.YOU_HAVE_UNREAD_CONVERSATION]: `คุณมี 0 การสนทนาที่ยังไม่ได้อ่านที่นี่`,
  [k.TRY_APPLYING_OTHER_FILTERS]: `ลองใช้ตัวกรองอื่น`,
  [k.EDIT_FILTER]: `แก้ไขตัวกรอง`,
  [k.SAVE_FILTER]: `บันทึกตัวกรอง`,
  [k.ERROR_EDITING_FILTER]: `เกิดข้อผิดพลาดในการแก้ไขตัวกรอง`,
  [k.FILTER_CANNOT_BE_SAVED]: `ไม่สามารถบันทึกตัวกรองได้`,
  [k.SORT_BY3]: `เรียงลำดับโดย`,
  [k.CUSTOM]: `กำหนดเอง`,
  [k.VIEW_AND_SELECT_FROM_ALL_STATU]: `ดูและเลือกจากทุกสถานะ`,
  [k.STATUS2]: `สถานะ`,
  [k.CLOSEFILTER]: `ปิดตัวกรอง`,
  [k.CLEAR]: `ล้าง`,
  [k.ALL_TYPES]: `ทุกประเภท`,
  [k.SAVED_FILTERS]: `ตัวกรองบันทึกไว้`,
  [k.QUICK_FILTERED_LISTS]: `รายการกรองเร็ว`,
  [k.SUGGESTED]: `แนะนำ`,
  [k.ALL_CONVERSATIONS]: `การสนทนาทั้งหมด`,
  [k.ALL_CONVERSATIONS1]: `การสนทนาทั้งหมด`,
  [k.FAVOURITES]: `รายการโปรด`,
  [k.FAVOURITES1]: `รายการโปรด`,
  [k.STAR]: `ดาว`,
  [k.ACTIVEQUICKFILTER]: `ตัวกรองเร็วที่ใช้งานอยู่`,
  [k.DEFAULTQUICKFILTER]: `ตัวกรองเร็ว mặc định`,
  [k.BACKARROW]: `ลูกศรกลับ`,
  [k.CHANGE]: `เปลี่ยน`,
  [k.CONVERSATION_TYPE_NOT_FOUND]: `ไม่พบประเภทการสนทนา`,
  [k.ALL_PROCESSES]: `ทุกกระบวนการ`,
  [k.ALL_TASKS]: `ทุกงาน`,
  [k.ALL_APPROVALS]: `ทุกการอนุมัติ`,
  [k.SEARCH_CONVERSATION]: `ค้นหาการสนทนา`,
  [k.ADD_A_DEPARTMENT]: `เพิ่มแผนก`,
  [k.SEARCH_FOR_DEPARTMENTS]: `ค้นหาแผนก`,
  [k.NO_DEPARTMENT]: `ไม่มีแผนก`,
  [k.CREATE_NEW_DEPARTMENT]: `สร้างแผนกใหม่`,
  [k.ENTER_YOUR_DEPARTMENT]: `ใส่ชื่อแผนกของคุณ`,
  [k.OBJECT1]: `วัตถุ`,
  [k.CLONE1]: `คลอน`,
  [k.ORGANISE]: `จัดระเบียบ`,
  [k.CREATE_NEW_FOLDER]: `สร้างโฟลเดอร์ใหม่`,
  [k.NEW_FOLDER]: `โฟลเดอร์ใหม่`,
  [k.MEDIUM]: `ขนาดกลาง`,
  [k.MOVE_HERE]: `ย้ายมาที่นี่`,
  [k.ALL_ORG_FILES]: `ไฟล์ทั้งหมดขององค์กร`,
  [k.MY_DRIVE]: `ไดรฟ์ของฉัน`,
  [k.V1]: `รุ่น`,
  [k.CURRENT_VERSION]: `รุ่นปัจจุบัน`,
  [k.MAKE_CURRENT1]: `ทำให้เป็นปัจจุบัน`,
  [k.VERSION_HISTORY]: `ประวัติรุ่น`,
  [k.PIN]: `ปัก`,
  [k.TO_ACTIVE_CONVERSATION]: `ไปยังการสนทนาที่ใช้งานอยู่`,
  [k.PIN1]: `ปัก`,
  [k.PIN_TO_ACTIVE_CONVERSATION]: `ปักไปยังการสนทนาที่ใช้งานอยู่`,
  [k.ORGANISE1]: `จัดระเบียบ`,
  [k.TRASH]: `ถังขยะ`,
  [k.SEE_RELATED_CONVERSATIONS]: `ดูการสนทนาที่เกี่ยวข้อง`,
  [k.FILE_NAME]: `ชื่อไฟล์`,
  [k.SIZE]: `ขนาด`,
  [k.LAST_MODIFIED]: `แก้ไขล่าสุด`,
  [k.UPLOADED_BY]: `อัปโหลดโดย`,
  [k.MY_FILES]: `ไฟล์ของฉัน`,
  [k.ALL_FILES]: `ไฟล์ทั้งหมด`,
  [k.PIN_CONVERSATION]: `ปักการสนทนา`,
  [k.PIN_FILE]: `ปักไฟล์ "`,
  [k.TO1]: `" ไปยัง "`,
  [k.ADD_MORE]: `เพิ่มอีก`,
  [k.OPTIONAL_COMMENT]: `ความคิดเห็น (ไม่บังคับ):`,
  [k.FILES_NOT_FOUND]: `ไม่พบไฟล์`,
  [k.NO_RESULT]: `ไม่มีผลลัพธ์`,
  [k.UPLOAD_FILE]: `อัปโหลดไฟล์`,
  [k.SORT_BY_ALPHABET]: `เรียงตามตัวอักษร`,
  [k.LAST_MODIFY]: `แก้ไขล่าสุด`,
  [k.RECENTLY_VIEWED]: `ดู 최근`,
  [k.UPLOAD_A_FILE]: `อัปโหลดไฟล์`,
  [k.UPLOAD_FILE1]: `อัปโหลดไฟล์`,
  [k.UPLOAD_NEW_VERSION1]: `อัปโหลดเวอร์ชันใหม่`,
  [k.THIS_FILE_IS_SHARED_ON_A_FOLDE]: `ไฟล์นี้ถูกแชร์ในโฟลเดอร์และกับเธรดอื่นด้วย การอัปโหลดเวอร์ชันใหม่จะเปลี่ยนไฟล์ทั้งหมด (แต่จะยังคงประวัติย้อนหลัง 5 เวอร์ชัน)`,
  [k.IT_LOOKS_LIKE_YOU_ARE_UPLOADIN]: `ดูเหมือนว่าคุณกำลังอัปโหลดเวอร์ชันใหม่ของไฟล์ "`,
  [k.IS_THAT_CORRECT]: `" นั่นถูกต้องไหม?`,
  [k.ENTER_VERSION_NOTES_COMMENTS]: `ใส่บันทึกเวอร์ชัน/ความคิดเห็น (ไม่บังคับ)`,
  [k.CONTINUE]: `ดำเนินการต่อ`,
  [k.NO_ORGS_WITH_GIVEN_NAME]: `ไม่พบองค์กรตามชื่อนี้`,
  [k.DASHBOARD1]: `แผงควบคุม`,
  [k.MANAGE1]: `จัดการ`,
  [k.CRASH]: `ล้มเหลว`,
  [k.DISPLAYNAME_ASC]: `แสดงชื่อ:เรียงตามตัวอักษร`,
  [k.ACTIVE]: `ใช้งานอยู่`,
  [k.CLONE_ORG]: `คลอนองค์กร`,
  [k.COPY_LINK]: `คัดลอกลิงก์`,
  [k.CREATE_NEW_ORG]: `สร้างองค์กรใหม่`,
  [k.TEAM_NAME]: `ชื่อทีม`,
  [k.INVITE_YOUR_TEAM_MEMBERS]: `เชิญสมาชิกทีมของคุณ`,
  [k.TEAM_MEMBER_OPTIONAL]: `สมาชิกทีม (ไม่บังคับ)`,
  [k.DOMAIN_NAME]: `ชื่อโดเมน`,
  [k.OPTIONAL1]: `(ไม่บังคับ)`,
  [k.ALLOW_ANYONE_WITH]: `อนุญาตให้ใครก็ตามที่มี`,
  [k.EMAIL_TO_JOIN_THIS_TEAM]: `อีเมลเข้าร่วมทีมนี้.`,
  [k.ALLOW_ANYONE_WITH_GIVEN_DOMAIN]: `อนุญาตให้ใครก็ตามที่มีโดเมนดังกล่าวเข้าร่วมทีมนี้`,
  [k.ORGDARK]: `orgDark`,
  [k.ORG2]: `org`,
  [k.CHANGE_PASSWORD]: `เปลี่ยนรหัสผ่าน`,
  [k.CIRCLECHECK]: `circleCheck`,
  [k.PASSWORD_DOES_NOT_MATCH]: `(รหัสผ่านไม่ตรงกัน)`,
  [k.CHANGE_ORG]: `เปลี่ยนองค์กร`,
  [k.UPDATE_NAME]: `อัปเดตชื่อ`,
  [k.CHANGE_PROFILE_PICTURE]: `เปลี่ยนรูปโปรไฟล์`,
  [k.CHANGE_PASSWORD1]: `เปลี่ยนรหัสผ่าน`,
  [k.ORG_SETTINGS1]: `การตั้งค่าองค์กร`,
  [k.A_NEW_VERSION_OF_UNIFIZE_HAS_B]: `มีเวอร์ชันใหม่ของ Unifize พร้อมให้ใช้งาน`,
  [k.UPDATE_NOW]: `อัปเดตทันที`,
  [k.VIEW_AS]: `ดูในนาม`,
  [k.SETTING_UP_YOUR_PROFILE]: `กำลังตั้งค่าโปรไฟล์ของคุณ`,
  [k.PLEASE_COMPLETE_YOUR_PROFILE_B]: `โปรดกรอกข้อมูลโปรไฟล์ด้านล่างเพื่อเริ่มใช้งาน Unifize`,
  [k.FULL_NAME]: `ชื่อ-นามสกุล`,
  [k.CREATE_PASSWORD]: `สร้างรหัสผ่าน`,
  [k.PROFILE_PIC]: `รูปโปรไฟล์`,
  [k.IMAGE1]: `image/*`,
  [k.ADD_PROFILE_PHOTO_OPTIONAL]: `เพิ่มรูปโปรไฟล์ (ไม่บังคับ)`,
  [k.SOMETHING_WENT_WRONG]: `มีบางอย่างผิดพลาด :(`,
  [k.PLEASE_TRY_CLICKING_ON_THE_INV]: `โปรดลองคลิกลิงก์เชิญอีกครั้ง!`,
  [k.SETTING_UP_UNIFIZE_FOR_YOU]: `กำลังตั้งค่า Unifize สำหรับคุณ`,
  [k.VERIFYING_CREDENTIALS]: `กำลังยืนยันข้อมูลรับรอง`,
  [k.DELETED_A]: `ลบแล้ว`,
  [k.FORWARDED_FROM]: `ส่งต่อจาก`,
  [k.HID_A_MESSAGE]: `ซ่อนข้อความ.`,
  [k.UNHIDE]: `เลิกซ่อน`,
  [k.FIRST]: `แรก`,
  [k.UNARCHIVE1]: `ยกเลิกการเก็บถาวร`,
  [k.IMG_LOADING]: `กำลังโหลดรูปภาพ...`,
  [k.UPDATED]: `อัปเดต`,
  [k.CANCELLED_THIS_CONVERSATION]: `ยกเลิกการสนทนานี้`,
  [k.CANCELLED1]: `cancelled`,
  [k.COMPLETED_THIS_CONVERSATION]: `ทำการสนทนานี้สำเร็จ`,
  [k.THIS_IS_YOUR_DIRECT_MESSAGE_WI]: `นี่คือข้อความตรงของคุณกับ @`,
  [k.THIS_CONVERSATION_IS_PRIVATE]: `การสนทนานี้เป็นส่วนตัว`,
  [k.REOPENED_THIS_CONVERSATION]: `เปิดการสนทนานี้ใหม่`,
  [k.REOPENED]: `เปิดใหม่`,
  [k.PHOTO1]: `รูปภาพ`,
  [k.STARTED_THIS_CONVERSATION]: `เริ่มการสนทนานี้`,
  [k.STARTED]: `เริ่มต้น`,
  [k.UPDATED_STATUS_OF_THIS_CONVERS]: `อัปเดตสถานะของการสนทนานี้เป็น`,
  [k.UPDATED_STATUS_OF_THE_CONVERSA]: `อัปเดตสถานะของการสนทนา`,
  [k.UPDATED_THE_DUE_DATE_TO]: `อัปเดตวันครบกำหนดเป็น`,
  [k.SET_THE_DUE_DATE_TO]: `กำหนดวันครบกำหนดเป็น`,
  [k.REMOVED_THE_DUE_DATE]: `ลบวันครบกำหนดแล้ว`,
  [k.READ]: `อ่าน`,
  [k.DELIVERED]: `ส่งถึง`,
  [k.TO2]: `ถึง:`,
  [k.CC1]: `คัดลอก:`,
  [k.BCC1]: `คัดลอกซ่อน:`,
  [k.NO_CONTENT]: `ไม่มีเนื้อหา`,
  [k.ATTACHMENTS]: `ไฟล์แนบ`,
  [k.ATTACHMENT]: `ไฟล์แนบ`,
  [k.HIDE_DETAILS]: `ซ่อนรายละเอียด`,
  [k.VIEW_FULL_EMAIL]: `ดูอีเมลฉบับเต็ม`,
  [k.LOADING2]: `กำลังโหลด...`,
  [k.DELETED_A_FILE]: `ลบไฟล์แล้ว.`,
  [k.MAIL]: `อีเมล`,
  [k.UPLOADED_A_FILE]: `อัปโหลดไฟล์`,
  [k.SENT_AN_EMAIL]: `ส่งอีเมลแล้ว`,
  [k.ADDED_A_FORM]: `เพิ่มฟอร์ม`,
  [k.TO_THE_CHECKLIST]: `ไปยังเช็คลิสต์`,
  [k.REMOVED_A_FORM]: `ลบฟอร์ม`,
  [k.FROM_THE_CHECKLIST1]: `ออกจากเช็คลิสต์`,
  [k.ADDED_PARENT]: `เพิ่มหัวข้อหลัก`,
  [k.REMOVED_PARENT]: `ลบหัวข้อหลัก`,
  [k.ADDED_CHILD]: `เพิ่มหัวข้อย่อย`,
  [k.REMOVED_CHILD]: `ลบหัวข้อย่อย`,
  [k.UPDATED_THE_OBJECTIVE_TO]: `อัปเดตวัตถุประสงค์เป็น:`,
  [k.ADDED_OBJECTIVE]: `เพิ่มวัตถุประสงค์:`,
  [k.REMOVED_OBJECTIVE]: `ลบวัตถุประสงค์`,
  [k.CHANGED_THE_OWNER_FROM]: `เปลี่ยนเจ้าของจาก`,
  [k.ASSIGNED]: `กำหนด`,
  [k.AS_THE_OWNER]: `เป็นเจ้าของ`,
  [k.REMOVED]: `ลบ`,
  [k.ADDED_CHILD_CONVERSATION]: `เพิ่มการสนทนาหัวข้อย่อย`,
  [k.REMOVED_CHILD_CONVERSATION]: `ลบการสนทนาหัวข้อย่อย`,
  [k.UPDATED_THE_PRIORITY_FROM]: `อัปเดตความสำคัญจาก`,
  [k.CHANGED_THE_PRIVACY_SETTING_TO]: `เปลี่ยนการตั้งค่าความเป็นส่วนตัวเป็น`,
  [k.TURNED_OFF_REMINDERS_FOR_THIS]: `ปิดการแจ้งเตือนสำหรับการสนทนานี้`,
  [k.TURN_ON]: `เปิดใช้งาน`,
  [k.TURNED_ON_REMINDERS_FOR_THIS_C]: `เปิดการแจ้งเตือนสำหรับการสนทนานี้`,
  [k.TURN_OFF]: `ปิด`,
  [k.UNIFIZE_ASSISTANT]: `Unifize Assistant`,
  [k.TURN_OFF_REMINDERS]: `ปิดการแจ้งเตือน`,
  [k.REMINDERS_HAVE_BEEN_TURNED_OFF]: `การแจ้งเตือนถูกปิดแล้ว`,
  [k.OF_THIS_CONVERSATION]: `ของการสนทนา`,
  [k.CREATED_A_REVISION]: `สร้างรีวิชัน`,
  [k.MADE]: `ทำให้`,
  [k.AS_THE_CURRENT_REVISION1]: `เป็นรีวิชันปัจจุบัน.`,
  [k.FROM]: `จาก #`,
  [k.FILES2]: `files`,
  [k.STARTED_THIS_CONVERSATION_FROM]: `เริ่มการสนทนานี้จาก`,
  [k.FORWARDED_FROM1]: `ส่งต่อจาก`,
  [k.SENT_THIS_MESSAGE_FROM]: `ส่งข้อความนี้จาก`,
  [k.THREAD]: `thread`,
  [k.DUE_ON]: `ครบกำหนด`,
  [k.UPDATED1]: `อัปเดต`,
  [k.TITLE_TO]: `ชื่อเรื่องเป็น`,
  [k.FROM1]: `จาก`,
  [k.ADDED]: `เพิ่ม`,
  [k.THE]: `the`,
  [k.INVITED]: `เชิญ`,
  [k.TO_THE_CHATROOM]: `เข้าสู่ห้องสนทนา`,
  [k.JOINED_THE_CONVERSATION]: `เข้าร่วมการสนทนาแล้ว`,
  [k.TODAY]: `วันนี้`,
  [k.LOADMORE]: `loadMore`,
  [k.LOADING_MORE_CONVERSATIONS]: `กำลังโหลดการสนทนาเพิ่มเติม . . .`,
  [k.SIGNATORIES]: `ผู้ลงนาม`,
  [k._40]: `@`,
  [k.MESSAGEWITHMENTION]: `ข้อความพร้อมการกล่าวถึง`,
  [k.MESSAGEWITHOUTMENTION]: `ข้อความไม่มีการกล่าวถึง`,
  [k.REPLY_AS_EMAIL]: `ตอบกลับเป็นอีเมล`,
  [k.FORWARD_AS_EMAIL]: `ส่งต่อเป็นอีเมล`,
  [k.START1]: `เริ่มต้น`,
  [k.STEP_DISMISSED]: `ข้ามขั้นตอนแล้ว`,
  [k.UNDO]: `ยกเลิก`,
  [k.HEY]: `เฮ้`,
  [k.CAN_YOU_COMPLETE_THIS_STEP]: `คุณช่วยทำขั้นตอนนี้ได้ไหม`,
  [k.DISMISS]: `ข้าม`,
  [k.CHECKLIST_FIELD_FILL]: `CHECKLIST_FIELD_FILL`,
  [k.YOU_CAN_ACCESS_THE_CONVERSATIO]: `คุณสามารถเข้าถึงการสนทนาได้หลังจากมีผู้เข้าร่วมอนุญาต`,
  [k.REQUEST_SENT]: `ส่งคำขอแล้ว`,
  [k.REQUEST_ACCESS]: `ขอเข้าถึง`,
  [k.THANK_YOU_FOR_YOUR_INTEREST]: `ขอบคุณสำหรับความสนใจของคุณ`,
  [k.OUR_SALES_TEAM_WILL_GET_IN_TOU]: `ทีมขายของเราจะติดต่อคุณในไม่ช้า`,
  [k.VALIDATING_YOUR_EMAIL]: `กำลังตรวจสอบอีเมลของคุณ`,
  [k.SET_UP_NEW_ORG_ON_UNIFIZE]: `ตั้งค่าองค์กรใหม่บน Unifize`,
  [k.PLEASE_ENTER_YOUR_WORK_EMAIL_S]: `กรุณาใส่อีเมลที่ทำงานของคุณเพื่อเชื่อมต่อกับพื้นที่ส่วนตัวขององค์กร`,
  [k.TRY_SIGNING_IN]: `ลองเข้าสู่ระบบ`,
  [k.BY_CLICKING_SIGN_UP_YOU_ACC]: `เมื่อคลิก "สมัครใช้งาน" ถือว่าคุณยอมรับ`,
  [k.TERMS_OF_SERVICE]: `เงื่อนไขการให้บริการ`,
  [k.AND_OUR]: `และ`,
  [k.PRIVACY_POLICY]: `นโยบายความเป็นส่วนตัว`,
  [k.SENDING_VERIFICATION_MAIL]: `กำลังส่งอีเมลยืนยัน`,
  [k.ERROR_SENDING_VERIFICATION_MAI]: `เกิดข้อผิดพลาดขณะส่งอีเมลยืนยัน`,
  [k.CLICK_HERE_TO_RESEND]: `คลิกที่นี่เพื่อส่งอีกครั้ง.`,
  [k.PLEASE_VERIFY_YOUR_EMAIL]: `โปรดยืนยันอีเมลของคุณ`,
  [k.PLEASE_OPEN_YOUR]: `. กรุณาเปิดอีเมลแล้วคลิกที่ลิงก์เพื่อใช้งาน Unifize`,
  [k.DIDN_T_GET_YOUR_EMAIL]: `ไม่ได้รับอีเมลหรือ?`,
  [k.ERROR_VERIFYING_YOUR_LINK]: `เกิดข้อผิดพลาดขณะยืนยันลิงก์ของคุณ`,
  [k.CHECK_YOUR_INBOX_FOR_THE_LATES]: `ตรวจสอบกล่องอีเมลสำหรับอีเมลล่าสุดจากเรา`,
  [k.ENTER_EMAIL_ADDRESS_TO_SIGN_UP]: `ใส่ที่อยู่อีเมลเพื่อสมัครใช้งาน`,
  [k.YOU_WILL_RECEIVE_A_VERIFICATIO]: `คุณจะได้รับอีเมลยืนยันภายในไม่กี่นาที`,
  [k.DEPARTMENT]: `แผนก`,
  [k.DEPARTMENT1]: `แผนก`,
  [k.PROCESS1]: `กระบวนการ`,
  [k.FILTERS1]: `ตัวกรอง:`,
  [k.SHOW_TOTAL]: `แสดงผลรวม`,
  [k.SHOW_AVERAGE]: `แสดงค่าเฉลี่ย`,
  [k.LAST_UPDATED]: `อัปเดตล่าสุด:`,
  [k.LAST_DAYS]: `7 วันที่ผ่านมา`,
  [k.LAST_MONTH]: `เดือนที่แล้ว`,
  [k.CHOOSE_DATE]: `เลือกวันที่`,
  [k.LAST_WEEK]: `สัปดาห์ที่แล้ว`,
  [k.ADD_DATE_RANGE_MANUALLY]: `เพิ่มช่วงวันที่ด้วยตนเอง`,
  [k.USER_NAME]: `ชื่อผู้ใช้`,
  [k.CONVERSATIONS_CREATED_DURING]: `การสนทนาที่ถูกสร้างในช่วง`,
  [k.WHERE_USER_IS_A_PARTICIPANT]: `ที่ผู้ใช้เป็นผู้เข้าร่วม`,
  [k.NOW_COMPLETED]: `เสร็จแล้วตอนนี้`,
  [k.STILL_PENDING]: `ยัง Pending อยู่`,
  [k.CURRENT_PENDING_CONVERSATIONS]: `การสนทนาที่ยัง Pending ในปัจจุบัน`,
  [k.UNREAD_BY_USER]: `ยังไม่ได้อ่านโดยผู้ใช้`,
  [k.OWNED_BY_USER]: `เป็นเจ้าของโดยผู้ใช้`,
  [k.MARKED_AS_CRITICAL]: `ถูกทำเครื่องหมายว่าสำคัญ`,
  [k.CONVERSATIONS_COMPLETED_DURING]: `การสนทนาที่เสร็จสิ้นในช่วง`,
  [k.CREATED_BY_USER]: `สร้างโดยผู้ใช้`,
  [k.COMPLETED_BY_USER]: `เสร็จสิ้นโดยผู้ใช้`,
  [k.LAST_ACTIVE]: `ใช้งานล่าสุด`,
  [k.AVERAGE]: `ค่าเฉลี่ย:`,
  [k.TOTAL]: `รวม:`,
  [k.CONTACTS]: `ติดต่อ`,
  [k.LOCK_GROUP]: `ล็อกกลุ่ม`,
  [k.ONLY_THE_GROUP_OWNER_CAN_MAKE]: `เฉพาะเจ้าของกลุ่มเท่านั้นที่แก้ไขได้เมื่อกลุ่มถูกล็อก`,
  [k.EDIT_GROUP]: `แก้ไขกลุ่ม`,
  [k.CREATE_NEW_GROUP]: `สร้างกลุ่มใหม่`,
  [k.GROUP_NAME]: `ชื่อกลุ่ม`,
  [k.ADD_PARTICIPANTS1]: `เพิ่มผู้เข้าร่วม`,
  [k.GROUP_OWNERS]: `เจ้าของกลุ่ม`,
  [k.CREATE_GROUP]: `สร้างกลุ่ม`,
  [k.GROUP_LOGO]: `โลโก้กลุ่ม`,
  [k.GROUP_NAME1]: `ชื่อกลุ่ม`,
  [k.GROUP_OWNERS1]: `เจ้าของกลุ่ม`,
  [k.ADDED_ON]: `เพิ่มเมื่อ`,
  [k.RESET_PASSWORD]: `รีเซ็ตรหัสผ่าน`,
  [k.RESEND_INVITE]: `ส่งคำเชิญอีกครั้ง`,
  [k.MMM_DD_YYYY_HH_MM_A1]: `MMM DD, YYYY hh:mm A`,
  [k.DATE_COMPLETED]: `วันที่เสร็จสมบูรณ์`,
  [k.GROUPS1]: `กลุ่ม (`,
  [k.PROCESSES1]: `กระบวนการ (`,
  [k.ONBOARDING_PROGRESS]: `ความก้าวหน้าในการเข้าร่วม`,
  [k.PHONE_NUMBER]: `หมายเลขโทรศัพท์`,
  [k.MESSAGE1]: `ข้อความ`,
  [k.ENABLE]: `เปิดใช้งาน`,
  [k.ADMIN]: `ผู้ดูแลระบบ`,
  [k.MAKE_MEMBER]: `ทำให้เป็นสมาชิก`,
  [k.MAKE_ADMIN]: `ทำให้เป็นแอดมิน`,
  [k.MMM_DD_YYYY_H_MM_A]: `MMM DD, YYYY h:mm a`,
  [k.MMM_DD_YYYY_H_MM_A1]: `MMM DD, YYYY h:mm A`,
  [k.COLUMN2]: `column-`,
  [k.ROLE]: `role`,
  [k.VIEW_PROFILE]: `ดูโปรไฟล์`,
  [k.DIRECT_MESSAGE]: `ข้อความโดยตรง`,
  [k.PEOPLE1]: `ผู้คน`,
  [k.ADMIN1]: `ผู้ดูแลระบบ?`,
  [k.MEMBER2]: `สมาชิก?`,
  [k.DISABLING_A_USER_S_ACCOUNT_WIL]: `การปิดใช้งานบัญชีผู้ใช้จะล้างข้อมูลแคชทั้งหมดของเขา`,
  [k.MAKE]: `ทำให้`,
  [k.S_ACCOUNT]: `บัญชีของ`,
  [k.USER_EXAMPLE_COM]: `user@example.com`,
  [k.ENTER_DISPLAY_NAME]: `ใส่ชื่อที่ต้องการแสดง`,
  [k.IN]: `in`,
  [k.SEARCH_FOR_COUNTRY]: `ค้นหาประเทศ`,
  [k.NO_PHONE_NUMBER]: `ไม่มีหมายเลขโทรศัพท์`,
  [k.ME2]: `me`,
  [k.CAN_T_FIND_ANYONE_WITH_THIS_NA]: `ไม่พบใครที่มีชื่อนี้.`,
  [k.CAN_T_FIND_SOMEONE]: `หาใครบางคนไม่เจอ?`,
  [k.TO_UNIFIZE_VIA_EMAIL]: `ถึง Unifize ผ่านอีเมล`,
  [k.INVITE_THEM_TO_UNIFIZE_VIA_EMA]: `เชิญพวกเขาใช้งาน Unifize ผ่านอีเมล`,
  [k.CIRCLE]: `circle`,
  [k.ME_ALWAYS_SELECTS_THE_VIEWER]: `Me (จะเลือกผู้ที่กำลังดูเสมอ)`,
  [k.SEARCH_PEOPLE]: `ค้นหาผู้คน`,
  [k.E_G_JOHN_DOE_XYZ_COM_OR_JOHN]: `เช่น john.doe@xyz.com หรือ John Doe`,
  [k.OUTLINE]: `outline`,
  [k.VIA_EMAIL]: `via email`,
  [k.USERWITHDISPLAYNAME]: `userWithDisplayName`,
  [k.SELECT_MEMBERS]: `เลือกสมาชิก`,
  [k.MEMBERS2]: `สมาชิก`,
  [k.MEMBER3]: `member-`,
  [k._41]: `30`,
  [k.PROFILE_PIC1]: `profile-pic`,
  [k.USER_NOT_FOUND]: `ไม่พบผู้ใช้`,
  [k.DEFINE]: `กำหนด`,
  [k.DRAFT_PROCESS]: `กระบวนการร่าง`,
  [k.SEARCH_PROCESS]: `ค้นหากระบวนการ`,
  [k.CANNOT_REMOVE_STATUS]: `ไม่สามารถลบสถานะ "`,
  [k.THERE_ARE_OPEN_CONVERSATIONS_W]: `มีการสนทนาที่ยังเปิดอยู่ในสถานะดังกล่าว คุณต้องเปลี่ยนสถานะทั้งหมดเป็นสถานะอื่นก่อนลบ "`,
  [k.CONVERSATIONS_MARKED_AS]: `การสนทนาที่ถูกกำหนดเป็น "`,
  [k.SEND_MAIL_WITHOUT_INVITES]: `ส่งเมลโดยไม่เชิญ`,
  [k.SEND_WITH_INVITES]: `ส่งพร้อมคำเชิญ`,
  [k._42]: `99+`,
  [k.CHAT_HISTORY]: `chat-history`,
  [k.BA]: `#1581BA`,
  [k._43]: `00`,
  [k.FFF]: `#fff`,
  [k._44]: `10`,
  [k._45]: `17`,
  [k._46]: `8`,
  [k._47]: `13`,
  [k._48]: `#888`,
  [k.EM3]: `1.5em`,
  [k.INITIAL]: `เริ่มต้น`,
  [k.A_A_A]: `#2A2A2A`,
  [k.F_A]: `#f1a208`,
  [k.BBBBBB]: `#BBBBBB`,
  [k.A_A_A1]: `#2a2a2a`,
  [k.URL_AVATAR_PATTERN]: `url(#avatar-pattern-`,
  [k.AVATAR_PATTERN]: `avatar-pattern-`,
  [k.OBJECTBOUNDINGBOX]: `objectBoundingBox`,
  [k.SCALE]: `scale(.00163)`,
  [k.GROUP_AVATAR]: `#group-avatar-`,
  [k.GROUP_AVATAR1]: `group-avatar-`,
  [k._49]: `612`,
  [k._50]: `7`,
  [k._51]: `18`,
  [k._52]: `0 0`,
  [k._53]: `12`,
  [k._54]: `24`,
  [k._55]: `#888888`,
  [k.F_ED]: `#2F80ED`,
  [k.IC_FORMAT_ALIGN_CENTER_PX]: `ic_format_align_center_24px`,
  [k.IC_FORMAT_ALIGN_JUSTIFY_PX]: `ic_format_align_justify_24px`,
  [k.IC_FORMAT_ALIGN_LEFT_PX]: `ic_format_align_left_24px`,
  [k.IC_FORMAT_ALIGN_RIGHT_PX]: `ic_format_align_right_24px`,
  [k.IC_FORMAT_LIST_BULLETED_PX]: `ic_format_list_bulleted_24px`,
  [k.IC_FORMAT_ITALIC_PX]: `ic_format_italic_24px`,
  [k.IC_FORMAT_LIST_NUMBERED_PX]: `ic_format_list_numbered_24px`,
  [k.IC_FORMAT_UNDERLINED_PX]: `ic_format_underlined_24px`,
  [k.UNABLE_TO_LOAD_DOCUMENT]: `ไม่สามารถโหลดเอกสารได้.`,
  [k.TO_CREATE_AREA_HIGHLIGHT_HOLD]: `หากต้องการสร้างพื้นที่ไฮไลต์ ให้กดปุ่ม ⌥ (Alt) จากนั้นคลิกแล้วลาก.`,
  [k._56]: `…`,
  [k.SCREENSHOT]: `ภาพหน้าจอ`,
  [k.RESET_HIGHLIGHTS]: `รีเซ็ตไฮไลต์`,
  [k.FUNCTION]: `function`,
  [k.PAGE1]: `.page`,
  [k.ADD_COMMENT]: `เพิ่มความคิดเห็น`,
  [k.ADD_A_COMMENT]: `เพิ่มความคิดเห็น`,
  [k.START_TYPING_YOUR_COMMENT_HERE]: `พิมพ์ความคิดเห็นของคุณที่นี่...`,
  [k.ADD_COMMENT1]: `เพิ่มความคิดเห็น`,
  [k.CUSTOM1]: `custom`,
  [k.AUTOMATIC_ZOOM]: `ซูมอัตโนมัติ`,
  [k.ACTUAL_SIZE]: `ขนาดจริง`,
  [k.PAGE_FIT]: `พอดีหน้า`,
  [k.PAGE_WIDTH]: `ความกว้างหน้า`,
  [k._57]: `75%`,
  [k._58]: `125%`,
  [k._59]: `150%`,
  [k._60]: `200%`,
  [k._61]: `300%`,
  [k._62]: `400%`,
  [k.PAGE_FIT1]: `page-fit`,
  [k.PAGE_ACTUAL]: `page-actual`,
  [k.PAGE_WIDTH1]: `page-width`,
  [k.EMAIL_PLACEHOLDER]: `ตัวอย่าง: john.doe@unifize.com`,
  [k.NEW_PASSWORD_PLACEHOLDER]: `ใส่รหัสผ่านใหม่`,
  [k.FILES_TOOLTIP_TITLE]: `ไฟล์`,
  [k.CHECKLIST_TOOLTIP_TITLE]: `เช็คลิสต์`,
  [k.REFRESH_CHECKLIST_TOOLTIP_TITLE]: `รีเฟรช`,
  [k.OPEN_CHECKLIST_TOOLTIP_TITLE]: `เปิดเช็คลิสต์`,
  [k.RESTRICTED_ROOM_MODAL_MESSAGE]: `ไม่สามารถโหลดห้องสนทนาที่คุณพยายามเข้าถึง โปรดลองใหม่ภายหลัง!`,
  [k.START_NEW]: `เริ่มใหม่`,
  [k.FEED]: `Feed`,
  [k.MORE_OPTIONS]: `ตัวเลือกเพิ่มเติม`,
  [k.GENERATE_JSON_SUCCESS]: `กำลังสร้าง JSON ลิงก์ดาวน์โหลดจะถูกส่งไปทางอีเมลในไม่กี่นาที`,
  [k.GENERATE_JSON_FAILURE]: `ไม่สามารถสร้าง JSON`,
  [k.PREVENT_REQUESTER_MESSAGE]: `ผู้ร้องขอไม่สามารถเพิ่มลายเซ็นได้.`,
  [k.ENTER_A_TITLE_FOR_THIS_PROCESS]: `ใส่ชื่อกระบวนการนี้`,
  [k.TEXT]: `Text`,
  [k.NUMBER]: `Number`,
  [k.DATE]: `Date`,
  [k.USER]: `User`,
  [k.SECTION]: `Section`,
  [k.SUBSECTION]: `Subsection`,
  [k.CONVERSATION2]: `Conversation`,
  [k.PARENT_CONVERSATION]: `Parent Conversation`,
  [k.CHILD_CONVERSATION]: `Child Conversation`,
  [k.PDF]: `Generate PDF`,
  [k.FILE_UPLOAD]: `File Upload`,
  [k.FORM]: `Form`,
  [k.REVISION]: `Revision`,
  [k.LINKED_FIELD]: `Linked field`,
  [k.UPLOAD_FIELDS_FROM_CSV]: `Upload fields from CSV`,
  [k.UPLOAD_FIELDS_JSON]: `Upload fields from JSON`,
  [k.RICH_TEXT]: `ข้อความที่อุดมไปด้วย`,
  [k.CHECKLIST_FIELD_TEXT_DESCRIPTION]: `ฟิลด์ข้อความสำหรับพิมพ์อิสระ`,
  [k.CHECKLIST_FIELD_NUMBER_DESCRIPTION]: `ฟิลด์ตัวเลข สามารถใช้วิเคราะห์ค่ารวมได้`,
  [k.CHECKLIST_FIELD_DATE_DESCRIPTION]: `ฟิลด์วันที่ ให้ผู้ใช้เลือกด้วยตนเองหรือใช้ตัวเลือกปฏิทิน`,
  [k.CHECKLIST_FIELD_USER_DESCRIPTION]: `เลือกผู้ใช้ในองค์กรหรือรายชื่อติดต่อของคุณ`,
  [k.CHECKLIST_FIELD_SECTION_DESCRIPTION]: `แบ่งเช็คลิสต์ออกเป็นส่วน ๆ (section) ที่ยุบ/ขยายได้`,
  [k.CHECKLIST_FIELD_SUBSECTION_DESCRIPTION]: `เพิ่มส่วนย่อย (sub-section) ภายในส่วนหลัก`,
  [k.CHECKLIST_FIELD_CONVERSATION_DESCRIPTION]: `เชื่อมโยงกระบวนการอื่นเป็นการสนทนาหัวข้อหลัก`,
  [k.CHECKLIST_FIELD_CHILD_CONVERSATION_DESCRIPTION]: `เชื่อมโยงกระบวนการอื่นเป็นการสนทนาหัวข้อย่อย`,
  [k.CHECKLIST_FIELD_SELECT_DESCRIPTION]: `ตัวเลือกแบบดรอปดาวน์ ผู้ใช้สามารถเพิ่มตัวเลือกได้ (ไม่บังคับ)`,
  [k.CHECKLIST_FIELD_PDF_DESCRIPTION]: `สร้าง PDF ที่พิมพ์ได้จากเช็คลิสต์โดยใช้เทมเพลต`,
  [k.CHECKLIST_FIELD_FILE_DESCRIPTION]: `แนบ/อัปโหลดเอกสารหรือรูปภาพในฟิลด์`,
  [k.CHECKLIST_FIELD_FORM_DESCRIPTION]: `เพิ่มฟอร์มที่กำหนดล่วงหน้า เหมาะสำหรับรายการหลายบรรทัด`,
  [k.CHECKLIST_FIELD_APPROVAL_DESCRIPTION]: `เพิ่มลายเซ็นดิจิทัลในกระบวนการหรือเอกสาร`,
  [k.CHECKLIST_FIELD_REVISION_DESCRIPTION]: `สร้างและจัดการรีวิชันของการสนทนา`,
  [k.CHECKLIST_FIELD_LINK_DESCRIPTION]: `เชื่อมโยงกับอีกกระบวนการ (สองทาง)`,
  [k.CHECKLIST_FIELD_UPLOAD_FROM_CSV_DESCRIPTION]: `อัปโหลดไฟล์ Excel เพื่อตั้งค่าเช็คลิสต์อย่างรวดเร็ว`,
  [k.TOOLTIP_HOMESCREEN]: `หน้าแรก`,
  [k.TOOLTIP_DASHBOARD]: `แดชบอร์ด`,
  [k.TOOLTIP_CONTACTS]: `ติดต่อ`,
  [k.TOOLTIP_MANAGE]: `จัดการ`,
  [k.TOOLTIP_DIRECT_MESSAGES]: `ข้อความโดยตรง`,
  [k.SEND_RESPONSE_FROM_HERE]: `ส่งคำตอบจากที่นี่`,
  [k.APPROVAL_PERMISSIONS]: `สิทธิ์อนุมัติ`,
  [k.APPROVAL_CAN_BE_CANCELLED_BY]: `อนุมัติสามารถถูกยกเลิกโดย`,
  [k.APPROVAL_SETTINGS_NOT_CONFIGURED]: `ยังไม่ได้กำหนดค่าการตั้งค่าอนุมัติ`,
  [k.DUE]: `ครบกำหนด`,
  [k.PROCESS_WAS_DELETED]: `กระบวนการถูกลบแล้ว`,
  [k.IMPORTED_WITH_ERRORS]: `นำเข้าพร้อมข้อผิดพลาด`,
  [k.SEARCH_PROCESS]: `ค้นหากระบวนการ`,
  [k.ONLY_IMAGES_ARE_ALLOWED]: `อนุญาตเฉพาะไฟล์รูปภาพเท่านั้น`,
  [k.UNABLE_TO_CLEAR_DB]: `ไม่สามารถล้างฐานข้อมูล: `,
  [k.PREFERENCES]: `การตั้งค่า`,
  [k.LANGUAGE]: `ภาษา`,
  [k.LANGUAGE_SWITCHING_HINT]: `เลือกภาษาที่คุณต้องการใช้ใน Unifize`,
  [k.LANGUAGE_SUPPORT_HINT]: `โปรดติดต่อผู้ดูแลของคุณเพื่อเปิดใช้งานการรองรับหลายภาษา`,
  [k.FIELD_NAME]: `ชื่อฟิลด์`,
  [k.ADD_NEW_FIELD]: `เพิ่มฟิลด์ใหม่`,
  [k.SETTINGS]: `การตั้งค่า`,
  [k.SHOW_HIDE_FIELD]: `แสดง / ซ่อนฟิลด์`,
  [k.TRANSLATIONS]: `การแปล`,
  [k.SUPPORTED_LANGUAGES]: `ภาษาที่รองรับ`,
  [k.LANGUAGE]: `ภาษา`,
  [k.ADD_NEW]: `+ เพิ่มใหม่`,
  [k.NO_TRANSLATIONS_FOUND]: `ไม่พบการแปลใดๆ`,
  [k.CONTACT_SUPPORT]: `ติดต่อ Unifize เพื่อขอการรองรับภาษาเพิ่มเติม`,
  [k.PREVIOUS]: `ก่อนหน้า`,
  [k.PAGE_SIZE]: `ขนาดหน้า`,
  [k.ENTER_PAGE_NUMBER]: `ใส่เลขหน้า`,
  [k.LANGUAGE_SWITCH]: `เปลี่ยนภาษา`,
  [k.LANGUAGE_INFO]: `ภาษาในองค์กรที่รองรับ:`,
  [k.ACTION]: `การกระทำ`,
  [k.PATH]: `เส้นทาง`,
  [k.FAILED_TO_SWITCH_LANGUAGE]: `ไม่สามารถเปลี่ยนภาษาได้`,
  [k.ENTER_OPTIONAL_COMMENT_HERE]: `ใส่ความคิดเห็น (ไม่บังคับ)`,
  [k.SELECT_ALL_PENDING]: `เลือกทั้งหมดที่ Pending`,
  [k.SELECT_ALL_COMPLETE]: `เลือกทั้งหมดที่ Complete`,
  [k.PENDING_CONVERSATIONS]: `ตัวอย่าง: การสนทนาที่ Pending`,
  [k.NUM_PENDING_TASKS]: `ตัวอย่าง: คุณมี #num งานที่ Pending`,
  [k.TAKE_ME_THERE]: `พาฉันไป`,
  [k.REFRESHED_CHART_DATA_NOT_AVAILABLE]: `ไม่พบข้อมูลแผนภูมิที่รีเฟรช โปรดลองใหม่ในไม่กี่นาที`,
  [k.AVAILABLE_CHARTS]: `แผนภูมิที่มีอยู่`,
  [k.SEARCH_CHARTS]: `ค้นหาแผนภูมิ`,
  [k.CHART_ALREADY_EXISTS_IN_DASHBOARD]: `แผนภูมินี้มีอยู่ในแดชบอร์ดแล้ว`,
  [k.FAILED_TO_LOAD_CHART]: `ไม่สามารถโหลดข้อมูลแผนภูมิ โปรดลองรีเฟรชภายหลัง`,
  [k.FAILED_TO_LOAD_CHART_DATA]: `ไม่สามารถโหลดแผนภูมิ โปรดลองรีเฟรชภายหลัง`,
  [k.NO_DATA_TO_DISPLAY]: `ไม่มีข้อมูลที่จะแสดง`,
  [k.DATE_UPDATED_SORT]: `วันที่อัพเดท`,
  [k.PRIORITY_SORT]: `ความสำคัญ`,
  [k.STATUS_SORT]: `สถานะ`,
  [k.DATE_CREATED_SORT]: `วันที่สร้าง`,
  [k.DUE_DATE_SORT]: `วันครบกำหนด`,
  [k.RECENT_SORT]: `ล่าสุด`,
  [k.STALE_SORT]: `ล้าสมัย`,
  [k.CRITICAL_SORT]: `วิกฤต`,
  [k.LOW_SORT]: `ต่ำ`,
  [k.PENDING_SORT]: `รอการตอบรับ`,
  [k.COMPLETE_SORT]: `เสร็จสิ้น`,
  [k.NEWEST_SORT]: `ใหม่สุด`,
  [k.OLDEST_SORT]: `เก่าสุด`,
  [k.DUE_SOON_SORT]: `ใกล้ครบกำหนด`,
  [k.DUE_LATER_SORT]: `ครบกำหนดภายหลัง`,
  [k.ENTER_FILTER_NAME]: `ใส่ชื่อตัวกรอง`,
  [k.ENTER_GROUP_NAME]: `ใส่ชื่อกลุ่ม`,
  [k.ADD_OWNER]: `เพิ่มเจ้าของ`,
  [k.ADD_CONTACTS]: `เพิ่มผู้ติดต่อ`,
  [k.ADD_TO_GROUP]: `เพิ่มเข้าสู่กลุ่ม`,
  [k.APPROVAL_CAN_ONLY_BE_SIGNED_BY]: `การอนุมัติสามารถลงนามได้โดย`,
  [k.ENTER_PASSWORD]: `ใส่รหัสผ่าน`,
  [k.ENTER_COMMENT_HERE]: `ใส่ความคิดเห็นที่นี่`,
  [k.RESENDING_OTP_TO_YOUR_EMAIL]: `กำลังส่ง OTP ไปยังอีเมลของคุณอีกครั้ง`,
  [k.OTP_SENT_TO_EMAIL]: `ได้ส่ง OTP ไปยังอีเมลของคุณ`,
  [k.FAILED_TO_SEND_OTP]: `ไม่สามารถส่ง OTP โปรดลองอีกครั้งภายหลัง`,
  [k.ENTER_OTP]: `ใส่ OTP 6 หลัก`,
  [k.INVITE_RECIPIENTS_TO_UNIFIZE]: `คุณต้องการเชิญผู้รับเข้าสู่ Unifize หรือไม่?`,
  [k.SEARCH_PEOPLE_OR_GROUPS]: `ค้นหาผู้คนหรือกลุ่ม`,
  [k.SELECT_ROLE_FOR_NEW_PARTICIPANT]: `เลือกบทบาทสำหรับผู้เข้าร่วมใหม่`,
  [k.ALL_COMMENTS_AND_MESSAGES]: `ความคิดเห็นและข้อความทั้งหมด`,
  [k.SEARCH_PICKLIST]: `ค้นหาในรายชื่อ`,
  [k.ENTER_TITLE_FOR_NEW_ITEM]: `ใส่ชื่อเรื่องสำหรับรายการใหม่`,
  [k.MARK_AS_FAVOURITE]: `ทำเครื่องหมายเป็นรายการโปรด`,
  [k.REVISION_PERMISSIONS]: `Revision permissions`,
  [k.REVISIONS_CAN_BE_CREATED_BY]: `Revision สามารถถูกสร้างได้โดย`,
  [k.REVISION_SETTINGS_NOT_CONFIGURED]: `ยังไม่ได้กำหนดค่า revision settings`,
  [k.ENTER_YOUR_NAME]: `ใส่ชื่อของคุณ`,
  [k.NEW_PASSWORD]: `รหัสผ่านใหม่`,
  [k.GENERATE_PDF]: `สร้าง PDF`,
  [k.MS_ADMIN_NOT_ENABLED]: `ยังไม่ได้เปิดใช้งาน Microsoft 365 Admin`,
  [k.UNABLE_TO_CREATE_FILE]: `ไม่สามารถสร้างไฟล์`,
  [k.MS_NOT_ENABLED]: `ยังไม่ได้เปิดใช้งาน Microsoft 365`,
  [k.BLANK]: `ว่าง`,
  [k.NON_BLANK]: `ไม่ว่าง`,
  [k.CANCEL_REQUEST]: `ยกเลิกคำขอ`,
  [k.CANCEL_APPROVAL]: `ยกเลิกการอนุมัติ`,
  [k.OPEN]: `เปิด`,
  [k.ENTER_OPTIONAL_TITLE]: `ใส่ชื่อ (ไม่บังคับ)`,
  [k.ENTER_ADDITIONAL_COMMENTS]: `ใส่ความคิดเห็นเพิ่มเติม`,
  [k.PENDING_INQUIRIES_FOR_STEAM]: `ตัวอย่าง: คำถามค้างเกี่ยวกับ Steam Turbines`,
  [k.GRAPHICAL_REPRESENTATION_THE_PENDING_ENQUIRIES]: `ตัวอย่าง: การแสดงกราฟของคำถามที่ค้างสำหรับ Steam Turbines โดยจัดกลุ่มตามเจ้าของ`,
  [k.SELECT_A_CHART_FOR]: `เลือกแผนภูมิสำหรับ`,
  [k.COUNT_OF_CONVERSATIONS]: `จำนวนการสนทนา`,
  [k.FOLLOWING_CHARTS_REPRESENT_CONVERSATIONS]: `แผนภูมิต่อไปนี้แสดงจำนวนการสนทนาพื้นฐาน`,
  [k.COUNT_OF_FORMS]: `จำนวนฟอร์ม`,
  [k.FOLLOWING_CHARTS_REPRESENT_FORMS]: `แผนภูมิต่อไปนี้แสดงจำนวนฟอร์มพื้นฐาน`,
  [k.SUM_OF_NUMERIC_FIELD]: `ผลรวมของฟิลด์ตัวเลข`,
  [k.USE_CHARTS_FOR_TOTALS]: `ใช้แผนภูมิเหล่านี้เมื่อคุณต้องการผลรวม เช่น มูลค่าเงินหรือจำนวนชั่วโมง`,
  [k.TIME_TRENDS]: `แนวโน้มตามเวลา`,
  [k.USE_CHARTS_FOR_TRENDS]: `ใช้แผนภูมิเหล่านี้เมื่อคุณต้องการดูแนวโน้มของจำนวนหรือค่าต่าง ๆ ตามเวลา`,
  [k.COMPARISON_CHART]: `Comparison Chart`,
  [k.USE_CHARTS_FOR_COMPARISON]: `ใช้แผนภูมิเหล่านี้เมื่อคุณต้องการเปรียบเทียบข้อมูลสองชุดหรือมากกว่า เช่น อัตราส่วนของข้อบกพร่องเทียบกับการผลิต`,
  [k.PIE_CHART]: `แผนภูมิเพียร์`,
  [k.BAR_CHART]: `แผนภูมิแท่ง`,
  [k.CHECKLIST_FIELDS]: `ฟิลด์เช็คลิสต์`,
  [k.CONVERSATION_ATTRIBUTES]: `คุณสมบัติการสนทนา`,
  [k.REFRESHED_PROCESS_TABLE]: `รีเฟรชตารางกระบวนการแล้ว`,
  [k.NEW_PROCESS_TEMPLATE]: `เทมเพลตกระบวนการใหม่`,
  [k.IMPORT]: `นำเข้า`,
  [k.DISPLAY_NAME]: `ชื่อแสดง`,
  [k.EMAIL_ID]: `ไอดีอีเมล`,
  [k.ROLE1]: `บทบาท`,
  [k.DEPARTMENT]: `แผนก`,
  [k.PHONE_NUMBER]: `หมายเลขโทรศัพท์`,
  [k.GROUPS]: `กลุ่ม`,
  [k.LAST_LOGGED_IN]: `เข้าระบบล่าสุด`,
  [k.DATE_INVITED]: `วันที่เชิญ`,
  [k.DATE_JOINED]: `วันที่เข้าร่วม`,
  [k.SEARCH_FOR_ANYTHING]: `ค้นหาอะไรก็ได้`,
  [k.DATE_UPDATED]: `วันที่อัปเดต`,
  [k.PRIORITY]: `ความสำคัญ`,
  [k.STATUS]: `สถานะ`,
  [k.DATE_CREATED]: `วันที่สร้าง`,
  [k.ALL_RELATED]: `ทั้งหมดที่เกี่ยวข้อง`,
  [k.OPEN_REVISIONS]: `รีวิชั่นเปิด`,
  [k.EXPORT]: `ส่งออก`,
  [k.REMOVE_CHART_FROM_DASHBOARD]: `ลบแผนภูมิออกจากแดชบอร์ด`,
  [k.REMOVE_CHART]: `ลบแผนภูมิ`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_CHART]: `คุณแน่ใจหรือไม่ว่าต้องการลบแผนภูมินี้ออกจากแดชบอร์ด?`,
  [k.TOMORROW]: `พรุ่งนี้`,
  [k.YESTERDAY]: `เมื่อวาน`,
  [k.ALL_EMBEDDED_RECORDS]: `ทั้งหมดที่ฝังอยู่`,
  [k.ERROR_MODIFYING_GROUP]: `เกิดข้อผิดพลาดในการแก้ไขกลุ่ม`,
  [k.SEARCH_ORG]: `ค้นหาองค์กร`,
  [k.ERROR_CREATING_CONVERSATION]: `เกิดข้อผิดพลาดในการสร้างการสนทนา`,
  [k.DISABLE_AND_CLEAR_ALL_DATA]: `ปิดใช้งานและล้างข้อมูลทั้งหมด`,
  [k.INVITE_SENT]: `ส่งคำเชิญแล้ว`,
  [k.INVITE_CLICKED]: `คลิกคำเชิญแล้ว`,
  [k.EMAIL_PASSWORD_REGISTRATION]: `การลงทะเบียนด้วย Email และรหัสผ่าน`,
  [k.PHONE_DEPT_REGISTRATION]: `การลงทะเบียนด้วยหมายเลขโทรศัพท์และแผนก`,
  [k.SIGNIN_MOBILE]: `เข้าสู่ระบบ (มือถือ)`,
  [k.SIGNIN_WEB]: `เข้าสู่ระบบ (เว็บ)`,
  [k.USE_THIS_SECTION_UPCOMING_TASKS]: `ใช้ส่วนนี้เพื่อแสดงงานที่กำลังจะมาถึง`,
  [k.HAS_USED_PLATFORM]: `ได้ใช้งานแพลตฟอร์มแล้ว`,
  [k.IS_PART_OF_GROUP]: `เป็นส่วนหนึ่งของกลุ่ม`,
  [k.IS_NOT_PART_OF_GROUP]: `ไม่ได้เป็นส่วนหนึ่งของกลุ่ม`,
  [k.UNTITLED_USER_SEGMENT]: `กลุ่มผู้ใช้ไม่มีชื่อ`,
  [k.SEARCH_DOMAINS]: `ค้นหาโดเมน`,
  [k.APPS]: `แอป`,
  [k.APPS_DESCRIPTION]: `รวมเข้ากับแอปที่คุณชื่นชอบและเพิ่มประสิทธิภาพเวิร์กโฟลว์ของคุณ`,
  [k.NO_APPS_FOUND]: `ไม่พบแอป`,
  [k.UNABLE_FETCH_INTEGRATIONS]: `ไม่สามารถดึงข้อมูลการผสานการทำงาน`,
  [k.UPDATE_INTEGRATION_FAILED]: `อัปเดตการผสานล้มเหลว`,
  [k.DISCONNECT_INTEGRATION_FAILED]: `ยกเลิกการเชื่อมต่อล้มเหลว`,
  [k.ENTER_TEXT]: `ใส่ข้อความ`,
  [k.ENTER_NUMBER]: `ใส่ตัวเลข`,
  [k.NEW_FORM_TEMPLATE]: `เทมเพลตฟอร์มใหม่`,
  [k.SELECT_CHECKLIST_FIELD]: `เลือกฟิลด์เช็คลิสต์`,
  [k.ENTER_CONVERSATION_TITLE]: `ใส่ชื่อการสนทนา`,
  [k.TURN_OFF_REMINDERS_CONTENT]: `คุณแน่ใจหรือไม่ว่าต้องการปิดการแจ้งเตือน?`,
  [k.ENTER_LABEL]: `ใส่ป้าย`,
  [k.THIS_WILL_BE_X_AXIS_SERIES]: `นี่จะเป็นแกน X (series)`,
  [k.UNTITLED_SERIES]: `ไม่มีชื่อชุดข้อมูล`,
  [k.AREA]: `พื้นที่`,
  [k.RATE]: `อัตราส่วน`,
  [k.LINE]: `เส้น`,
  [k.ENTER_TRENDLINE_LABEL]: `ใส่ป้ายกำกับเทรนด์ไลน์`,
  [k.TOTAL_OF_NUMERICAL_FIELD]: `ผลรวมของฟิลด์ตัวเลข`,
  [k.AVERAGE_OF_NUMERICAL_FIELD]: `ค่าเฉลี่ยของฟิลด์ตัวเลข`,
  [k.NESTED_FIELDS_DESCRIPTION]: `สำหรับฟิลด์ที่ซ้อนกัน จะรวมผลในฟอร์มหรือฟิลด์ที่เชื่อมโยงก่อน แล้วจึงหาค่าเฉลี่ย`,
  [k.RECORD_COUNT]: `จำนวนระเบียน`,
  [k.SUM]: `ผลรวม`,
  [k.AVERAGE]: `ค่าเฉลี่ย`,
  [k.AVERAGE_OF_SUM]: `ค่าเฉลี่ยของผลรวม`,
  [k.ALL_RECORDS_FILTER]: `ฟิลเตอร์ทั้งหมดของระเบียน`,
  [k.ALL_RECORDS_FILTER_DESCRIPTION]: `- หากปิดใช้งาน (ค่าเริ่มต้น): จะกรองเฉพาะระเบียนหลักของกระบวนการ แม้จะมีค่าหลายค่า - หากเปิดใช้งาน: จะกรองระเบียนทั้งหมดด้วย ทำให้คุณเห็นเฉพาะฟอร์มที่มีค่าตรง`,
  [k.SEARCH_TYPE]: `ประเภทการค้นหา`,
  [k.SUBTITLE]: `ชื่อรอง`,
  [k.YOU_HAVE_PENDING_TASKS]: `ตัวอย่าง: คุณมีงานค้าง`,
  [k.YOU_HAVE_NUM_PENDING_TASKS]: `ตัวอย่าง: คุณมี #num งานค้าง`,
  [k.SEARCH_TILES]: `ค้นหาไทล์`,
  [k.SELECT_ITEMS]: `เลือกไอเท็ม`,
  [k.WRONG_PASSWORD_MESSAGE]: `(ต้องมีอย่างน้อย 8 ตัวอักษร, มีตัวพิมพ์เล็ก, ตัวพิมพ์ใหญ่ และตัวเลข)`,
  [k.CONFIRM_PASSWORD]: `ยืนยันรหัสผ่าน`,
  [k.UNTITLED]: `ไม่มีชื่อ`,
  [k.SEARCH_ROLES]: "ค้นหาบทบาท",
  [k.SEARCH_DEPARTMENTS]: "ค้นหาแผนก",
  [k.SEARCH_GROUPS]: "ค้นหากรุ๊ป",
  [k.A_FEW_SECONDS_AGO]: "เมื่อไม่กี่วินาทีที่ผ่านมา",
  [k.A_FEW_MINUTES_AGO]: "เมื่อไม่กี่นาทีที่ผ่านมา"
};
